import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import { SCHEDULE_PAYLOAD_NAMES } from "../types";
import Checkbox from "../../CheckBox/Checkbox";

import { CombinedDataTypes, VehicleDataTypes } from "../../../shared";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface DownloadDataTypesCheckboxesProps {
  selectedDataTypes: CombinedDataTypes[];
  handleChange: (name: SCHEDULE_PAYLOAD_NAMES, value: VehicleDataTypes) => void;
}

const DownloadDataTypesCheckboxes = ({
  selectedDataTypes,
  handleChange,
}: DownloadDataTypesCheckboxesProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  return (
    <div data-testid="download-data-types-checkboxes">
      <Typography
        variant="body2"
        sx={{ color: colors.textSecondary, marginBottom: "8px" }}
      >
        {t("Dialog##download data types")}
      </Typography>
      {Object.values(VehicleDataTypes).map((value) => (
        <div key={`schedule_${value}`}>
          <Checkbox
            id={`schedule_${value}`}
            checked={selectedDataTypes.includes(value)}
            disabled={value === VehicleDataTypes.Overview}
            onChange={() =>
              handleChange(SCHEDULE_PAYLOAD_NAMES.FileTypes, value)
            }
            label={t(`Schedules##file types##${value}`)}
          />
        </div>
      ))}
    </div>
  );
};

export default DownloadDataTypesCheckboxes;
