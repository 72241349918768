import { useTranslation } from "react-i18next";

import DriverLastActivityState from "../components/DriverLastActivityState/DriverLastActivityState";
import SharedStatusBadge from "../../SharedStatusBadge/SharedStatusBadge";
import MultiValuesCellItem from "../components/MultiValuesCellItem/MultiValuesCellItem";
import VehicleDataTypesList from "../../Schedules/components/VehicleDataTypesList/VehicleDataTypesList";
import { COLUMN_NAMES, COLUMN_RESOURCES, useDateTime } from "../../../shared";
import DateTime from "../../../shared/components/dateTime/DateTime";
import { parseResource } from "../../../shared/functions/functions";
import useCustomResources from "../../../shared/hooks/useCustomResources/useCustomResources";

const useTableFunctions = () => {
  const {
    renderLastDownloadAttemptBadgeWithTooltip,
    renderNextDownloadAttemptText,
  } = useCustomResources();

  const { t } = useTranslation();
  const { currentTimeFormatHoursMinutes } = useDateTime();

  const renderStringResource = (
    name: COLUMN_NAMES,
    value: string | number | null
  ) => {
    const dateTimeValues = [
      COLUMN_NAMES.SeenAt,
      COLUMN_NAMES.CreatedAt,
      COLUMN_NAMES.LastConnected,
      COLUMN_NAMES.UpdatedAt,
      COLUMN_NAMES.DownloadTime,
      COLUMN_NAMES.ActivityPeriod,
      COLUMN_NAMES.InvitedAt,
    ];

    if (value === null || value === "") {
      return "-";
    }

    if (dateTimeValues.includes(name)) {
      return (
        <DateTime date={String(value)} format={currentTimeFormatHoursMinutes} />
      );
    }

    return value;
  };

  const renderValue = (
    name: COLUMN_NAMES,
    resource: COLUMN_RESOURCES,
    data: any,
    secondCellValue?: COLUMN_RESOURCES
  ) => {
    if (resource === COLUMN_RESOURCES.NextDownload) {
      return <>{renderNextDownloadAttemptText(data.nextDownload, true)}</>;
    }

    if (resource === COLUMN_RESOURCES.LastDownload) {
      return renderLastDownloadAttemptBadgeWithTooltip(data);
    }
    if (resource === COLUMN_RESOURCES.Status) {
      return <SharedStatusBadge status={data.status} />;
    }
    if (resource === COLUMN_RESOURCES.LastActivity) {
      return <DriverLastActivityState lastActivity={data.lastActivity} />;
    }

    if (resource === COLUMN_RESOURCES.InvitationStatus) {
      return (
        <SharedStatusBadge
          status={data.invitationStatus}
          customTooltipText=""
        />
      );
    }

    if (secondCellValue) {
      const firstParsedValue = parseResource(resource, data);
      const secondParsedValue = parseResource(secondCellValue, data);
      return (
        // display two date values in one cell
        <>
          <MultiValuesCellItem
            title={t("General##start")}
            value={renderStringResource(name, firstParsedValue)}
          />
          <MultiValuesCellItem
            title={t("General##end")}
            value={renderStringResource(name, secondParsedValue)}
          />
        </>
      );
    }
    // display vehicle data types icons
    if (resource === COLUMN_RESOURCES.FileTypes) {
      return (
        data[resource] && <VehicleDataTypesList schedule={data[resource]} />
      );
    } else {
      const parsedValue = parseResource(resource, data);
      return renderStringResource(name, parsedValue);
    }
  };

  return { renderValue, renderStringResource };
};

export default useTableFunctions;
