/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import DetailsCardItemText from "../../../../DetailsCardItemText";
import DetailsCardItemTitle from "../../../../DetailsCardItemTitle";

import { useStyles } from "../../../../../../../styles";
import { CompanyCardDto } from "../../../../../../../types";

import SharedStatusBadge from "../../../../../../../../SharedStatusBadge/SharedStatusBadge";

import { ThemeContext } from "../../../../../../../../../context/theme/ThemeContextProvider";
import {
  COMPANY_CARD_STATUS,
  useDateTime,
} from "../../../../../../../../../shared";

interface CompanyCardContentProps {
  cardData: CompanyCardDto;
}

const CompanyCardContent = ({ cardData }: CompanyCardContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { formattedDate } = useDateTime();

  const { paperCardItem, paperCardItemAlignStart } = useStyles(colors);

  const formattedCardDate = formattedDate(cardData.disconnectedAt);

  const customTooltipDate =
    cardData.status === COMPANY_CARD_STATUS.Disconnected
      ? { connectionDate: formattedCardDate }
      : undefined;

  return (
    <>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle
          title={t("Company cards##status")}
          customLineHeight="24px"
        />

        <SharedStatusBadge
          status={cardData.status as COMPANY_CARD_STATUS}
          tooltipSize="medium"
          customTooltipText={t(
            `Company cards##badge tooltip##${cardData.status}`,
            customTooltipDate
          )}
        />
      </div>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Company cards##valid until")} />
        <DetailsCardItemText
          textToRender={formattedDate(cardData.validUntil)}
        />
      </div>
      <div css={css([paperCardItem, paperCardItemAlignStart])}>
        <DetailsCardItemTitle title={t("Company cards##company card id")} />
        <DetailsCardItemText
          textToRender={cardData.companyCardId}
          hasCopyButton
          copiedValue={cardData.companyCardId}
        />
      </div>
    </>
  );
};

export default CompanyCardContent;
