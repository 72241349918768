/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { ReactNode, useContext } from "react";

import { styleVariants } from "./functions";
import { useStyles } from "./styles";
import { BadgeColors } from "./types";

import Tooltip from "../Tooltip/Tooltip";
import { TooltipSizes } from "../Tooltip/types";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

export interface BadgeProps {
  children: string | ReactNode;
  color: BadgeColors;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  inverted?: boolean;
  tooltipText?: string;
  tooltipSize?: TooltipSizes;
  bubbleOnly?: boolean;
  customStyle?: {};
}

const Badge = ({
  children,
  color,
  iconLeft,
  iconRight,
  inverted,
  tooltipText,
  tooltipSize,
  bubbleOnly,
  customStyle,
}: BadgeProps) => {
  const { colors } = useContext(ThemeContext);

  const { badgeDiv, badgeIconLeft, badgeIconRight } = useStyles(bubbleOnly);

  const textColor = styleVariants(inverted, colors)[color].text;
  const borderColor = styleVariants(inverted, colors)[color].border;
  const bgColor = styleVariants(inverted, colors)[color].background;

  return (
    <div
      css={css([
        {
          color: textColor,
          backgroundColor: bgColor,
          border: `1px solid ${borderColor}`,
          cursor: tooltipText ? "pointer" : "unset",
        },
        badgeDiv,
        customStyle,
      ])}
    >
      {iconLeft && <span css={css(badgeIconLeft)}>{iconLeft}</span>}

      {tooltipText ? (
        <Tooltip
          data-testid={
            typeof children === "string" && `${children}-status-tooltip`
          }
          title={tooltipText}
          placement="top"
          size={tooltipSize ?? "small"}
          hasArrow
        >
          <Typography variant="subtitle1semiBold">{children}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="subtitle1semiBold">{children}</Typography>
      )}

      {iconRight && <span css={css(badgeIconRight)}>{iconRight}</span>}
    </div>
  );
};

export * from "./types/interfaces";
export default Badge;
