import { SyntheticEvent, useState } from "react";
import { AxiosError } from "axios";
import { useRecoilState } from "recoil";

import useLogin from "./useLogin";

import axios from "../../../api/axios";
import { ApiResources } from "../../../api/resources";
import useTwoFA from "../../../components/Navbar/components/Settings/hooks/useTwoFA";
import { loginState } from "../../../atoms/atoms";

export const useTwoFactorVerification = ({ ...props }) => {
  const { storeTokensAndNavigateToHomepage } = useLogin({});
  const { handleTwoFaError } = useTwoFA({});

  const [, setIsLoginState] = useRecoilState(loginState);

  const [code, setCode] = useState("");
  const [error, setError] = useState<string | null>(null);

  const { setIsLoading, setIsButtonLoading, token } = props;

  const handleChange = (newValue: string) => {
    setError(null);
    setCode(newValue);
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const payload = {
      otpCode: code,
    };

    try {
      // set login state to display auth loader on Users/Me request
      setIsLoginState(true);
      setIsLoading(true);
      setIsButtonLoading(true);
      const response = await axios.post(
        ApiResources.CreateTwoFactorToken,
        payload,
        // This endpoint requires to send authorization token.
        // Tokens are not stored yet into local storage, so that user can't access the app without 2FA
        // (that is why useAxiosPrivate() is not used here).
        // The token is sent in the header here, by using axios 'headers' object.
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      storeTokensAndNavigateToHomepage(response);
    } catch (error) {
      handleTwoFaError(error as AxiosError, setError);
    } finally {
      setIsLoading(false);
      setIsButtonLoading(false);
    }
  };

  return {
    otpCode: code,
    twoFaError: error,
    handleOtpChange: handleChange,
    handle2FaSubmit: handleSubmit,
  };
};
