import PageHeaderActions from "../PageHeaderActions";
import { useDialog } from "../../hooks/useDialog";
import InviteUserDialog from "../../dialogs/users/InviteUserDialog/InviteUserDialog";
import { ROUTES, TABLE_NAMES, USERS_ACTIONS } from "../../../../../shared";
import { useAuth } from "../../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../shared/featureToggles/types";

interface UsersHeaderActionsProps {
  currentRoute: ROUTES;
}

const UsersHeaderActions = ({ currentRoute }: UsersHeaderActionsProps) => {
  const { isReleaseFeatureEnabledForUser } = useReleaseFeatureToggle();

  const { user } = useAuth();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const tableName = TABLE_NAMES.Users;

  return (
    <>
      <PageHeaderActions
        route={currentRoute}
        openDialog={openDialog}
        inviteUser={
          user &&
          isReleaseFeatureEnabledForUser(
            FEATURE_NAMES.ShowInviteUser,
            user?.email
          )
        }
      >
        <InviteUserDialog
          isOpen={isDialogOpen[USERS_ACTIONS.InviteUser]}
          onClose={closeDialog}
          tableName={tableName}
        />
      </PageHeaderActions>
    </>
  );
};

export default UsersHeaderActions;
