import { ThemeColors } from "../../../../shared";

export const emptyDoughnut = {
  id: "emptyDoughnut",
  afterDraw(chart: any, _args: any, options: any) {
    const { datasets } = chart.data;

    const { color, width, radiusDecrease } = options;
    let hasData = false;

    let total: number = 0;
    datasets.forEach((dataset: any) =>
      dataset.data.map((item: number) => {
        total = total + item;
        return total;
      })
    );

    if (total > 0) {
      hasData = true;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      // wait for the chart to be drawn to avoid flash of empty doughnut
      chart &&
        setTimeout(() => {
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;
          ctx?.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
          ctx?.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
          ctx?.stroke();
        }, 100);
    }
  },
};

export const cutout = 50;
export const diameter = "142";
export const topAlignment = "48";
export const centerItemsLineHeight = "16";

export const customTooltip = (colors: any) =>
  function(context: any) {
    let tooltipEl = document.getElementById("chartjs-tooltip");

    const statusElement = context.tooltip.body[0].lines[0];

    // Create element on first render
    // do not show tooltip for fake elements, created for spacing between elements
    if (!tooltipEl && statusElement !== " undefined") {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }
    function getBody(bodyItem: any) {
      return bodyItem.lines;
    }

    if (tooltipEl) {
      const tooltipModel = context.tooltip;
      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = "0";
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      // Set Text
      if (tooltipModel.body) {
        const bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = "<thead>";

        innerHtml += "</thead><tbody>";

        bodyLines.forEach(function(body: any, i: number) {
          const afterLabel = tooltipModel.body[i].after[0];
          const colors = tooltipModel.labelColors[i];
          let style = "background: white";
          style += "; border-color:" + colors.borderColor;

          let dotStyle =
            "background:" +
            `${tooltipModel.labelColors[i].backgroundColor}` +
            "; " +
            "border:" +
            `1px solid ${tooltipModel.labelColors[i].borderColor} !important` +
            "; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 6px";

          const labelSpan =
            '<span style="font-weight: 600; font-size: 14px; line-height: 20px; ' +
            style +
            '">' +
            body +
            "</span>";

          const dot = '<span style="' + dotStyle + '"></span>';

          const afterLabelSpan =
            '<span style="font-weight: 400; font-size: 14px; line-height: 20px; ">' +
            afterLabel +
            "</span>";

          innerHtml +=
            "<tr><td>" +
            dot +
            labelSpan +
            "</td></tr><tr><td>" +
            afterLabelSpan +
            "</td></tr>";
        });
        innerHtml += "</tbody>";

        let tableRoot: any = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
      }

      const position = context.chart.canvas.getBoundingClientRect();

      // Display, position, and set styles
      tooltipEl.style.opacity = "1";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.display =
        statusElement === " undefined" ? "none" : "block";

      tooltipEl.style.left =
        position.left + window.scrollX + tooltipModel.caretX + "px";

      tooltipEl.style.top =
        position.top + window.scrollY + tooltipModel.caretY + "px";

      tooltipEl.style.padding = "8px";
      tooltipEl.style.pointerEvents = "pointer";
      tooltipEl.style.background = colors.white;
      tooltipEl.style.color = colors.textDark;
      tooltipEl.style.boxShadow = "0px 2px 8px rgba(15, 23, 42, 0.16)";
      tooltipEl.style.borderRadius = "8px";
    }
  };

export const options: any = (colors: ThemeColors, data: any) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        // disable default tooltip
        enabled: false,
        external: customTooltip(colors),
        callbacks: {
          label: function(context: any) {
            let label = ` ${context.dataset.labels[context.dataIndex]}`;
            return label;
          },

          afterLabel: function(tooltipItem: any, data: any) {
            let secondRow = ` ${
              tooltipItem.dataset.data[tooltipItem.dataIndex]
            } devices`;
            return secondRow;
          },
        },
      },
      legend: {
        display: false,
      },
      emptyDoughnut: {
        color: colors.gray300,
        width: 16,
        radiusDecrease: 10,
        cutout: cutout,
      },
    },
    cutout: cutout,
  };
};
