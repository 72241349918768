import { Dispatch, SetStateAction, useContext } from "react";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { TableDetailsDataStateType } from "../../../atoms/types";
import { getPageGlobalStateObjectValue } from "../../../components/Table/functions";
import { deleteStoredPageParams, LANGUAGES, ROUTES } from "../../../shared";
import { AlertContext } from "../../../context/alert/AlertContextProvider";
import { useAuth } from "../../../context/Auth/AuthProvider";
import {
  currPage,
  selectedTableRows,
  tableDetails,
  tableDetailsSectionState,
  tablePageData,
} from "../../../atoms/atoms";
import { setTokensToLocalStorage } from "../../../shared/functions/auth";
import LanguageContext from "../../../context/language/LanguageContext";
import { setStoredLanguage } from "../../../shared/functions/functions";

const useLogin = ({ ...props }: any) => {
  const { removeAlert } = useContext(AlertContext);
  const { language } = useContext(LanguageContext);

  const {
    setTokenInfo,
    setErrorMessageFromServer,
    toggle2FaVerification,
  } = useAuth();

  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setPageToShow] = useRecoilState(currPage);
  const [, setSelectedRows] = useRecoilState(selectedTableRows);
  const [, setPageData] = useRecoilState(tablePageData);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);

  const {
    setUserName,
    validateEmail,
    clearEmailError,
    setPassword,
    clearPasswordError,
  } = props;

  const from = location.state?.from?.pathname || ROUTES.Vehicles;

  const resetAllPreviousValues = () => {
    deleteStoredPageParams();
    setTableDetails((prev: TableDetailsDataStateType | null) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    setPageToShow({
      vehiclesPage: null,
      driversPage: null,
    });
    setSelectedRows([]);
    setPageData(null);
    setIsDetailsOpen(false);
    setErrorMessageFromServer(false);
    // should remove unauthorized message if user was not authenticated previously
    removeAlert();
  };

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setUserName(event.target.value);
    validateEmail(event);
    clearEmailError();
  };

  const handlePswInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPassword(event.target.value);
    clearPasswordError("password1");
  };

  const display2FaVerificationScreen = (
    response: AxiosResponse<any, any>,
    setToken: Dispatch<SetStateAction<string>>
  ) => {
    toggle2FaVerification(true);
    setErrorMessageFromServer(false);
    setToken(response.data.token);
  };

  const storeTokensAndNavigateToHomepage = (
    response: AxiosResponse<any, any>
  ) => {
    setTokenInfo(response.data);
    setTokensToLocalStorage(response.data);
    i18n.changeLanguage(language);
    setStoredLanguage(language || LANGUAGES.English);

    // reset previous values
    resetAllPreviousValues();

    navigate(from, { replace: true });
  };

  return {
    handleEmailInputChange,
    handlePswInputChange,
    display2FaVerificationScreen,
    storeTokensAndNavigateToHomepage,
  };
};

export default useLogin;
