/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import ListChoice from "../../../../../../../ListChoice/ListChoice";
import RemoveCircleoutlinedIcon from "../../../../../../../../assets/customIcons/actions/RemoveCircleOutlinedIcon";

interface RemoveCompanyCardButtonProps {
  onOptionClick: () => void;
}

const RemoveCompanyCardButton = ({
  onOptionClick,
}: RemoveCompanyCardButtonProps) => {
  const { t } = useTranslation();

  return (
    <ListChoice
      data-testid="remove-company-card-option"
      title={t("Details##remove")}
      iconLeft={<RemoveCircleoutlinedIcon css={css({ alignSelf: "center" })} />}
      onClick={onOptionClick}
      alignIconCenter
    />
  );
};

export default RemoveCompanyCardButton;
