import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RemoveCircleoutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="currentColor"
      sx={{ width: props.width || "16px", height: props.height || "16px" }}
    >
      <g clipPath="url(#clip0_5601_39795)">
        <path
          d="M4.66671 7.9987C4.66671 8.36536 4.96671 8.66536 5.33337 8.66536H10.6667C11.0334 8.66536 11.3334 8.36536 11.3334 7.9987C11.3334 7.63203 11.0334 7.33203 10.6667 7.33203H5.33337C4.96671 7.33203 4.66671 7.63203 4.66671 7.9987ZM8.00004 1.33203C4.32004 1.33203 1.33337 4.3187 1.33337 7.9987C1.33337 11.6787 4.32004 14.6654 8.00004 14.6654C11.68 14.6654 14.6667 11.6787 14.6667 7.9987C14.6667 4.3187 11.68 1.33203 8.00004 1.33203ZM8.00004 13.332C5.06004 13.332 2.66671 10.9387 2.66671 7.9987C2.66671 5.0587 5.06004 2.66536 8.00004 2.66536C10.94 2.66536 13.3334 5.0587 13.3334 7.9987C13.3334 10.9387 10.94 13.332 8.00004 13.332Z"
          fill="#111729"
        />
      </g>
      <defs>
        <clipPath id="clip0_5601_39795">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RemoveCircleoutlinedIcon;
