import { PASSWORD_TYPE } from "../../Pages/Auth/types";
import { CompaniesDto } from "../../shared/types/interfaces";
export const dummyNotificationsCount = 0;

export const dummyVehiclesDetailsHandlesResponseData = {
  id: "1",
  imei: "12345",
  name: "TST7 7777777777",
  comment: "Comment",
  company: {
    id: "123",
    name: "Root",
  },
  lastConnected: null,
  status: "Disabled",
  createdAt: "2023-09-04T13:08:42",
  createdBy: "System",
  updatedAt: "2023-09-04T13:08:42",
  updatedBy: "System",
  deleted: false,
  disabled: true,
};

export const dummyCompaniesApiResponseData = {
  companies: [
    {
      id: "Root Company",
      name: "Root Company",
    },
    {
      id: "Default",
      name: "Default",
    },
  ],
};

export const dummyCompaniesApiResponse = {
  data: {
    companies: [
      {
        id: "Root Company",
        name: "Root Company",
      },
    ],
  },
};
export const dummyUserRolesApiResponseData = [
  {
    id: "User",
    name: "User",
  },
  {
    id: "Admin",
    name: "Admin",
  },
];
export const dummyUserRolesApiResponse = {
  data: ["User", "CompanyAdministrator", "TeltonikaSupport"],
};

export const dummyCompanies: CompaniesDto[] = [
  {
    id: "13c3b2b2-416f-480a-9318-7856cc0db195",
    name: "ChildCompany2",
  },
  {
    id: "2",
    name: "Default",
  },
  {
    id: "25e7f33a-8717-4aa8-83c5-768a97f91762",
    name: "ChildCompany1",
  },
];

const dummyToggleVisibility = (pswType: string) => {
  const showPassword = {
    current: false,
    newPsw: false,
    repeat: false,
  };

  const PasswordVisibility = () => <div>{pswType}</div>;
  return { showPassword, PasswordVisibility };
};

export const dummyResetPasswordInputs = [
  {
    name: PASSWORD_TYPE.NewPsw,
    title: "New password",
    TooltipPsw: dummyToggleVisibility(PASSWORD_TYPE.NewPsw),
  },
  {
    name: PASSWORD_TYPE.Repeat,
    title: "Repeat password",
    TooltipPsw: dummyToggleVisibility(PASSWORD_TYPE.Repeat),
  },
];

export const dummySchedulePeriodTypes = [
  {
    id: "Daily_schedule_period",
    name: "Daily",
    value: "Daily",
    days: 1,
  },
  {
    id: "Weekly_schedule_period",
    name: "Every 7 days",
    value: "Weekly",
    days: 7,
  },
  {
    id: "Biweekly_schedule_period",
    name: "Every 14 days",
    value: "Biweekly",
    days: 14,
  },
  {
    id: "Every28Days_schedule_period",
    name: "Every 28 days",
    value: "Every28Days",
    days: 28,
  },
  {
    id: "Every90Days_schedule_period",
    name: "Every 90 days",
    value: "Every90Days",
    days: 90,
  },
  {
    id: "Custom_schedule_period",
    name: "Custom",
    value: "Custom",
    days: 0,
  },
];

export const dummyCsvFileSample = {
  path: "sample_vehicles_import_list.csv",
  name: "sample_vehicles_import_list.csv",
  lastModified: 1678436513175,
};

export const dummyLocation = {
  pathname: "/vehicles",
  search: "",
  hash: "",
  state: null,
  key: "jgoezxsq",
};

export const dummyFileFormats = [
  {
    id: "DDD_settings_dropdown",
    name: ".DDD",
    value: "DDD",
  },
  {
    id: "V1BC1B_settings_dropdown",
    name: ".V1B, .C1B",
    value: "V1BC1B",
  },
  {
    id: "TGD_settings_dropdown",
    name: ".TGD",
    value: "TGD",
  },
];
