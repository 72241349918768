import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    dialogTitleWithTooltip: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  } as CSSObject);
