import { Typography } from "@mui/material";

import { useMediaQueries } from "../../../../../shared";

interface IDetailsCardItemTitleProps {
  title: string;
  customLineHeight?: string;
}

const DetailsCardItemTitle = ({
  title,
  customLineHeight,
}: IDetailsCardItemTitleProps) => {
  const { toMd } = useMediaQueries();

  const titleLineHeight = customLineHeight ?? "inherit";

  const PAPER_CARD_ITEM_TITLE_STYLE = toMd
    ? {
        width: "156px",
      }
    : {
        minWidth: "112px",
        maxWidth: "112px",
        marginRight: "16px",
      };

  return (
    <Typography
      sx={[PAPER_CARD_ITEM_TITLE_STYLE, { lineHeight: titleLineHeight }]}
      variant="body2"
    >
      {title}:
    </Typography>
  );
};

export default DetailsCardItemTitle;
