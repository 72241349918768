import { useTranslation } from "react-i18next";
import { useState } from "react";

import useImeiValidation from "./useImeiValidation";
import { INPUT_LENGTH, INPUT_VALUES_LENGTH } from "..";
import { VehicleDataDto } from "../../Pages/Vehicles/interfaces";

const useVehiclesFormInputs = (rowData?: VehicleDataDto) => {
  const { t } = useTranslation();

  const [vehicleName, setVehicleName] = useState<string | null>(
    rowData?.number ?? null
  );
  const [imeiValue, setImeiValue] = useState<string | null>(
    rowData?.imei ?? null
  );
  const [descriptionValue, setDescriptionValue] = useState<string | null>(
    rowData?.comment ?? ""
  );
  const [isVehicleDeactivated, setIsVehicleDeactivated] = useState<boolean>(
    rowData?.disabled ?? false
  );
  const [nameInputError, setNameInputError] = useState<string | null>(null);
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);

  const {
    imeiIsValid,
    imeiError,
    setImeiError,
    validateImei,
  } = useImeiValidation(imeiValue);

  const validateNameLength = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length > INPUT_LENGTH.VehicleName) {
      setNameInputError(
        t("Dialog##name not valid", {
          maxNumber: INPUT_LENGTH.VehicleName,
        })
      );
    }
    if (inputValue.length === 0) {
      setNameInputError(t("Dialog##name should not be empty"));
    }
  };
  const validateDescriptionLength = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length > INPUT_LENGTH.Description) {
      setDescriptionInputError(
        t("Dialog##description not valid", {
          maxNumber: INPUT_LENGTH.Description,
        })
      );
    }
  };
  const resetAllValues = () => {
    setVehicleName(null);
    setImeiValue(null);
    setImeiError?.(null);
    setNameInputError(null);
    setDescriptionInputError(null);
    setIsVehicleDeactivated(false);
  };

  const handleChangeNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setVehicleName(e.target.value);
    validateNameLength(e);
    setNameInputError(null);
  };

  const handleChangeImeiValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imeiValueFromInput = e.target.value;

    e.preventDefault();
    setImeiValue(imeiValueFromInput);
    validateImei(e);

    imeiValueFromInput.length <= INPUT_VALUES_LENGTH.Imei &&
      setImeiError?.(null);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDescriptionValue(e.target.value);
    validateDescriptionLength(e);
    setDescriptionInputError(null);
  };

  const handleToggleDeactivateVehicle = () => {
    setIsVehicleDeactivated(!isVehicleDeactivated);
  };

  return {
    descriptionInputError,
    descriptionValue,
    imeiError,
    imeiIsValid,
    imeiValue,
    isVehicleDeactivated,
    nameInputError,
    vehicleName,
    handleChangeDescription,
    handleChangeImeiValue,
    handleChangeNameValue,
    handleToggleDeactivateVehicle,
    resetAllValues,
    setImeiError,
    validateDescriptionLength,
    validateImei,
    validateNameLength,
  };
};

export default useVehiclesFormInputs;
