import { DeviceStatus } from "./types/enums";

import { ThemeColors } from "../../../../shared";

export const statusColor = (itemName: string, colors: ThemeColors) => {
  switch (itemName) {
    case DeviceStatus.Online:
      return colors.green600;
    case DeviceStatus.Offline:
      return colors.gray200;
    case DeviceStatus.Inactive:
      return colors.white;
    case DeviceStatus.Deactivated:
      return colors.red700;
    default:
      return "";
  }
};

export const statusBorderColor = (itemName: string, colors: ThemeColors) => {
  switch (itemName) {
    case DeviceStatus.Online:
      return colors.green600;
    case DeviceStatus.Offline:
      return colors.gray400;
    case DeviceStatus.Inactive:
      return colors.gray400;
    case DeviceStatus.Deactivated:
      return colors.red700;
    default:
      return "";
  }
};
