import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import Checkbox from "../../../../../CheckBox/Checkbox";
import InputField from "../../../../../InputField/InputField";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { inputFieldMarginBottom } from "../../../../../../shared";

interface IAddVehicleDialogContent {
  oncNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onImeiValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggleVehicleDeactivate: () => void;
  imeiErrorText?: string | null;
  validateImei: (event: any) => void;
  validateName: (event: any) => void;
  nameErrorText: string | null;
  validatedescription: (event: any) => void;
  descriptionErrorText: string | null;
}

const AddVehicleDialogContent = ({
  oncNameChange,
  onImeiValueChange,
  onDescriptionChange,
  toggleVehicleDeactivate,
  imeiErrorText,
  validateImei,
  validateName,
  nameErrorText,
  validatedescription,
  descriptionErrorText,
}: IAddVehicleDialogContent) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="add-vehicle-company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="vehicle-number-input-field"
        size="medium"
        fullWidth
        required
        errorText={nameErrorText}
        onBlur={validateName}
        onChange={oncNameChange}
        labelLeft={t("Dialog##vehicle number")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        data-testid="imei-input-field"
        size="medium"
        fullWidth
        required
        errorText={imeiErrorText}
        onBlur={validateImei}
        onChange={onImeiValueChange}
        labelLeft={t("Dialog##imei")}
        placeholder={t("Dialog##imei placeholder")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        data-testid="description-input-field"
        size="medium"
        fullWidth
        errorText={descriptionErrorText}
        onBlur={validatedescription}
        onChange={onDescriptionChange}
        labelLeft={t("Dialog##description")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <div>
        <Checkbox
          id={uuidv4()}
          label={t("Dialog##deactivate vehicle")}
          onChange={toggleVehicleDeactivate}
        />
      </div>
    </>
  );
};

export default AddVehicleDialogContent;
