import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

export const VEHICLES_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.VehicleNumber,
    isSticky: true,
    resource: COLUMN_RESOURCES.Number,
    sortResource: COLUMN_RESOURCES.Number,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.Imei,
    isSticky: false,
    resource: COLUMN_RESOURCES.Imei,
    isDefaultActive: true,
    isDisabled: true,
    width: "150px",
  },
  {
    name: COLUMN_NAMES.Status,
    isSticky: false,
    resource: COLUMN_RESOURCES.Status,
    isDefaultActive: true,
    isBadge: true,
  },
  {
    name: COLUMN_NAMES.LastConnected,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastConnected,
    sortResource: COLUMN_RESOURCES.LastConnected,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.LastDownload,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastDownload,
    sortResource: COLUMN_RESOURCES.LastDownload,
    isDefaultActive: true,
    isBadge: true,
  },
  {
    name: COLUMN_NAMES.NextDownload,
    isSticky: false,
    resource: COLUMN_RESOURCES.NextDownload,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Schedules,
    isSticky: false,
    resource: COLUMN_RESOURCES.Schedules,
    sortResource: COLUMN_RESOURCES.Schedules,
  },
  {
    name: COLUMN_NAMES.CreatedBy,
    resource: COLUMN_RESOURCES.CreatedBy,
    sortResource: COLUMN_RESOURCES.CreatedBy,
  },
  {
    name: COLUMN_NAMES.CreatedAt,
    resource: COLUMN_RESOURCES.CreatedAt,
    sortResource: COLUMN_RESOURCES.CreatedAt,
  },
];
