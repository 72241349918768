import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";

import { UsersDataDto } from "../../../../../Pages/Users/types/types";
import { RenderConditionOptions, USERS_ACTIONS } from "../../../../../shared";

const useUsersInlineActions = () => {
  const [rowData, setRowData] = useState<UsersDataDto | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setIsOpen(false);
  };

  const actionTitle = USERS_ACTIONS.EditUser;

  const handleEditUser = (data: UsersDataDto) => {
    openDialog(data);
  };

  const isUserHimself = (id: number, loggedUserId: number) =>
    id === loggedUserId;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      icon: <EditIcon />,
      clickAction: (data: UsersDataDto) => handleEditUser(data),
      renderConditions: ({ data, user }: RenderConditionOptions) =>
        !isUserHimself(data?.id, user?.id),
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useUsersInlineActions;
