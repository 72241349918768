export const usersFeatures = {
  showInviteUser: [
    "evaldas.macijauskas@teltonika.lt",
    "laura.pociuniene@teltonika.lt",
    "tadas.zeimo@teltonika.lt",
    "dominykas.grauzinis@teltonika.lt",
    "matej.puljic@teltonika.lt",
    "paulius.garbasauskas@teltonika.lt",
    "emilis.dolgov@teltonika.lt",
    "remigijus.pankevicius@teltonika.lt",
    "autotests@teltonika.lt",
  ],
};
