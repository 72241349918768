import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    singleRowCSS: {
      display: "flex",
      alignItems: "start",
      wordBreak: "break-word",
      gap: "8px",

      ":not(:last-child)": {
        marginBottom: "4px",
      },
    },
  } as CSSObject);
