import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";

import SchedulesMainDialogContent from "./SchedulesMainDialogContent";

import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import { deselectAllRows } from "../../../../../Table/functions";
import ConfirmCloseDialog from "../../../../../Schedules/components/ConfirmCloseDialog/ConfirmCloseDialog";
import { ResourceParams } from "../../../../../Schedules/types";
import useTutorialMode from "../../../../../TutorialMode/hooks/useTutorialMode";

import {
  GeneralDialogProps,
  getValueInSearchField,
  TABLE_NAMES,
  useMediaQueries,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import {
  selectedTableRows,
  selectedVehicleSchedule,
  tableDetails,
} from "../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { refreshTableAndUpdateDetails } from "../../../../../../shared/functions/functions";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../../../roles/types/enums";

interface SchedulesMainDialogProps {
  resourceParams: ResourceParams;
}

const SchedulesMainDialog = ({
  isOpen,
  resourceParams,
  onClose,
}: GeneralDialogProps & SchedulesMainDialogProps) => {
  const { getAccessStatus } = useAuth();
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { handleCommonError, handleResponse } = useResponse();
  const { putData } = useAxiosPrivate();
  const { isTutorialOpen } = useTutorialMode();
  const { getTableItemDetails } = useTableItemDetails();

  const currentTableDetails = useRecoilValue(tableDetails);

  const [selectedRows, setSelectedRows] = useRecoilState<any>(
    selectedTableRows
  );

  const [selectedSchedule, setSelectedSchedule] = useRecoilState(
    selectedVehicleSchedule
  );

  const [
    isCloseDialogConfirmationOpen,
    setIsCloseDialogConfirmationOpen,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const resourceCount = selectedRows?.length;
  const isReady = selectedSchedule && resourceCount > 0;

  const { actionMain, resourceName } = resourceParams;

  const handleCloseSchedulesMainDialog = () => {
    if (resourceCount === 0 || isTutorialOpen) {
      onClose(actionMain);
    } else {
      setIsCloseDialogConfirmationOpen(true);
    }
  };

  const handleConfirmCloseDialog = (
    closeVehicleSchedules: boolean,
    closeConfirmOnly: boolean
  ) => {
    setIsCloseDialogConfirmationOpen(false);

    if (closeVehicleSchedules && !closeConfirmOnly) {
      onClose(actionMain);
    }
  };

  const handleSuccess = (
    selectedtableRows: any,
    isVehicleSchedules: boolean
  ) => {
    const currentTableDetailsFromPage = isVehicleSchedules
      ? currentTableDetails?.vehiclesPage
      : currentTableDetails?.driversPage;

    const itemHasOpenedDetails =
      currentTableDetails &&
      selectedtableRows.includes(currentTableDetailsFromPage?.id);

    const successMessage = t(`Alert##apply ${actionMain} success`, {
      resourceCount,
    });

    const valueInSearchField = getValueInSearchField(resourceParams);

    const matchingValue = itemHasOpenedDetails
      ? currentTableDetailsFromPage?.id
      : undefined;

    refreshTableAndUpdateDetails({
      valueInSearchField,
      resourceId: matchingValue,
      successMessage,
      itemHasOpenedDetails,
      getTableItemDetails,
      handleResponse,
    });

    deselectAllRows(setSelectedRows);
    handleConfirmCloseDialog(true, false);
  };

  const handleSubmit = async () => {
    try {
      if (isReady) {
        setIsLoading(true);

        const isVehicleSchedules = resourceName === TABLE_NAMES.Vehicles;

        const payload = {
          scheduleId: selectedSchedule.id,
          ...(isVehicleSchedules
            ? { vehicleIds: selectedRows }
            : { driverIds: selectedRows }),
        };

        await putData(resourceParams.apiResourceApply, payload);

        handleSuccess(selectedRows, isVehicleSchedules);
      }
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsLoading(false);
  };

  const deselectSchedule = () => {
    setSelectedSchedule(null);
  };

  const canManageSchedules = getAccessStatus(USER_PERMISSIONS.ManageSchedules);

  const descriptionType = canManageSchedules
    ? "description"
    : "description read-only";

  return (
    <>
      <Dialog
        id="schedulesDialogWindow"
        title={t(`Schedules##${actionMain}##title`)}
        description={t(`Schedules##${actionMain}##${descriptionType}`)}
        extendToMaxWidth={toMd}
        isTitleSeparator
        isActionsSeparator
        disableEscapeKeyDown={isTutorialOpen}
        open={isOpen}
        close={handleCloseSchedulesMainDialog}
        submit={handleSubmit}
        width={!toMd ? DialogWidth.Dialog920px : undefined}
        TransitionProps={{
          onExited: deselectSchedule,
        }}
        actions={
          <DialogActions
            onClose={handleCloseSchedulesMainDialog}
            isLoading={isLoading}
            isreadyToConfirm={isReady}
            hideAcceptButton={!canManageSchedules}
            confirmationButtonText={t("Button##apply")}
            confirmationButtonTestId={`schedules-main-dialog-apply-button`}
            cancelButtonText={t("Button##close")}
            cancelButtonTestId={`schedules-main-dialog-close-button`}
          />
        }
      >
        <SchedulesMainDialogContent
          resourceParams={resourceParams}
          resourceCount={resourceCount}
          canManageSchedules={canManageSchedules}
          deselectSchedule={deselectSchedule}
        />
      </Dialog>
      <ConfirmCloseDialog
        resourceName={resourceName}
        isOpen={isCloseDialogConfirmationOpen}
        isLoading={isLoading}
        selectedSchedule={selectedSchedule}
        closeWithMainDialog={handleConfirmCloseDialog}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default SchedulesMainDialog;
