/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import TwoFA from "./TwoFA/TwoFA";
import ChangePassword from "./ChangePassword/ChangePassword";

import Button from "../../../../../Button/Button";
import Dialog from "../../../../../Dialog/Dialog";

interface SecuritySettingsProps {
  onCloseDialog: () => void;
  isOpen: boolean;
}

const SecuritySettings = ({ onCloseDialog, isOpen }: SecuritySettingsProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="security-settings-dialog"
      title={t("Dialog##security settings")}
      description={t("Dialog##security settings description")}
      extendToMaxWidth
      isTitleSeparator
      isActionsSeparator
      actions={
        <Button
          variant="textOnly"
          size="normal"
          fullWidth
          color="secondary"
          onClick={onCloseDialog}
        >
          {t("Button##close")}
        </Button>
      }
      close={onCloseDialog}
      open={isOpen}
    >
      <div css={css({ width: "100%" })}>
        <ChangePassword />
        <TwoFA />
      </div>
    </Dialog>
  );
};

export default SecuritySettings;
