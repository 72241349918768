/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { ReactNode } from "react";

import { useStyles } from "../styles";
import { ThemeColors } from "../../../../../../../shared";
import { useStyles as sharedStyles } from "../../../../../../../shared/styles/sharedStyles";
import RectangleLoader from "../../../../../../../shared/components/loaders/RectangleLoader";

interface AccordionDetailsCardTitleAreaProps {
  displayLoader: boolean;
  title: string;
  colors: ThemeColors;
  titleInlineAddition?: string;
  quickAction?: ReactNode;
}

const AccordionDetailsCardTitleArea = ({
  displayLoader,
  title,
  colors,
  titleInlineAddition,
  quickAction,
}: AccordionDetailsCardTitleAreaProps) => {
  const {
    acordionDetailsCardTitleContainer,
    acordionDetailsCardTitle,
  } = useStyles({});
  const { flexbox } = sharedStyles();

  return (
    <div
      css={css(acordionDetailsCardTitleContainer)}
      data-testid="accordion-details-card-title"
    >
      {displayLoader ? (
        <RectangleLoader
          height="16px"
          width="216px"
          testId={`accordion-details-card-title-loader-${title}`}
        />
      ) : (
        <div css={css(acordionDetailsCardTitle)}>
          <div css={css(flexbox)}>
            <Typography variant="body2Bold">{title}</Typography>
            {/* empty space: */}
            <Typography variant="body2">&nbsp;</Typography>
            {titleInlineAddition && (
              <Typography
                variant="body2"
                css={css({ color: colors.textPlaceholder })}
              >
                {titleInlineAddition}
              </Typography>
            )}
          </div>
          {quickAction}
        </div>
      )}
    </div>
  );
};

export default AccordionDetailsCardTitleArea;
