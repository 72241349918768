import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { setOptionsArrayfromQueryResponse } from "../functions";
import { ALERT_STATUS } from "../../../context/alert/types";
import useResponse from "../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import useScheduleRepeatValues from "../../Schedules/hooks/useScheduleRepeatValues/useScheduleRepeatValues";

const useQueryDropdown = (resource: string) => {
  const { handleResponse } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  const initialized = useRef(false);

  const { getRepeatString } = useScheduleRepeatValues();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setIsLoading(true);
      const getQueryData = async () => {
        try {
          const response = await getData(resource);
          setOptions(
            setOptionsArrayfromQueryResponse(
              response,
              resource,
              getRepeatString,
              t
            )
          );
        } catch (error) {
          const err = error as AxiosError;
          if (err) {
            handleResponse(ALERT_STATUS.Critical, err.message);
          }
        } finally {
          setIsLoading(false);
        }
      };

      getQueryData();
    }
  }, [getData, getRepeatString, handleResponse, resource, t]);

  return { isLoading, options };
};

export default useQueryDropdown;
