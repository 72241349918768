/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import EnquiryText from "../EnquiryText/EnquiryText";
import ResetSent from "../ResetSent/ResetSent";
import AuthContainer from "../../AuthContainer";
import Button from "../../../../../components/Button/Button";
import InputField from "../../../../../components/InputField/InputField";
import { ROUTES, useEmailValidation } from "../../../../../shared";
import { ApiResources } from "../../../../../api/resources";
import useResponse from "../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../context/alert/types";
import axios from "../../../../../api/axios";

const PasswordResetRequest = () => {
  const { handleResponse } = useResponse();

  const [email, setEmail] = useState("");

  const {
    emailIsValid,
    emailError,
    setEmailError,
    clearEmailError,
    validateEmail,
  } = useEmailValidation(email);
  const { t } = useTranslation();

  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const showError = emailIsValid ? "" : emailError;
  const payload = {
    email,
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailIsValid) {
      setEmailError(t("Auth##invalid email format"));
    } else {
      try {
        setLoading(true);
        await axios.post(ApiResources.SendResetPasswordLink, payload);
        setEmail("");
        handleRedirection();
      } catch (error) {
        const err = error as AxiosError;
        if (err) {
          handleResponse(ALERT_STATUS.Critical, err.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setEmail(event.target.value);
    validateEmail(event);
    clearEmailError();
  };

  const handleRedirection = useCallback(() => setSent(!sent), [sent]);

  return sent ? (
    <ResetSent redirect={handleRedirection} />
  ) : (
    <AuthContainer
      formName={t("Auth##forgot your password")}
      secondaryText={t("Auth##enter email")}
    >
      <form noValidate onSubmit={handleSubmit}>
        <InputField
          data-testid="forgot-password-input-field"
          size="medium"
          id="standard-error-helper-text"
          fullWidth
          name="email"
          onBlur={validateEmail}
          errorText={showError}
          onChange={handleEmailInputChange}
          labelLeft={t("Auth##email")}
          placeholder={`${t("Auth##example")} email@tacho.com`}
          css={css({
            marginBottom: "16px",
          })}
        />

        <Button
          data-testid="resend-password-button"
          fullWidth
          variant="textOnly"
          size="normal"
          color="primary"
          type="submit"
          isLoading={loading}
        >
          {t("Auth##send reset link")}
        </Button>

        <EnquiryText
          primaryText={t("Auth##remember password")}
          linkText={t("Auth##sign in")}
          component={Link}
          to={ROUTES.Home}
        />
      </form>
    </AuthContainer>
  );
};

export default PasswordResetRequest;
