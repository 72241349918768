import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { EditVehiclePayloadType } from "../../../../types";
import Checkbox from "../../../../../CheckBox/Checkbox";
import InputField from "../../../../../InputField/InputField";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { inputFieldMarginBottom, INPUT_LENGTH } from "../../../../../../shared";

import { VehicleDataDto } from "../../../../../../Pages/Vehicles/interfaces";
import useVehiclesFormInputs from "../../../../../../shared/hooks/useVehiclesFormInputs";

interface IEditVehicleDialogContentProps {
  rowData: VehicleDataDto;
  setPayload: Dispatch<SetStateAction<EditVehiclePayloadType>>;
  setIsReadyToConfirm: Dispatch<SetStateAction<boolean | "" | null>>;
}

const EditVehicleDialogContent = ({
  rowData,
  setPayload,
  setIsReadyToConfirm,
}: IEditVehicleDialogContentProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const {
    descriptionInputError,
    descriptionValue,
    imeiError,
    imeiIsValid,
    imeiValue,
    isVehicleDeactivated,
    nameInputError,
    vehicleName,
    handleChangeDescription,
    handleChangeImeiValue,
    handleChangeNameValue,
    handleToggleDeactivateVehicle,
    validateDescriptionLength,
    validateImei,
    validateNameLength,
  } = useVehiclesFormInputs(rowData);

  const nameIsValid =
    vehicleName && vehicleName.length <= INPUT_LENGTH.VehicleName;

  const descriptionIsValid =
    (descriptionValue && descriptionValue.length <= INPUT_LENGTH.Description) ||
    !descriptionValue;

  const isPayloadReadyToConfirm =
    vehicleName &&
    imeiValue &&
    nameIsValid &&
    descriptionIsValid &&
    imeiIsValid;

  useEffect(() => {
    if (rowData?.id) {
      setPayload({
        id: rowData.id,
        imei: imeiValue,
        name: vehicleName,
        comment: descriptionValue,
        disabled: isVehicleDeactivated,
      });

      setIsReadyToConfirm(isPayloadReadyToConfirm);
    }
  }, [
    descriptionValue,
    imeiValue,
    isPayloadReadyToConfirm,
    isVehicleDeactivated,
    rowData,
    vehicleName,
    setIsReadyToConfirm,
    setPayload,
  ]);

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="add-vehicle-company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="vehicle-number-input-field"
        value={vehicleName}
        size="medium"
        fullWidth
        required
        errorText={nameIsValid ? "" : nameInputError}
        onBlur={validateNameLength}
        onChange={handleChangeNameValue}
        labelLeft={t("Dialog##vehicle number")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        data-testid="imei-input-field"
        size="medium"
        value={imeiValue}
        fullWidth
        required
        errorText={imeiIsValid ? "" : imeiError}
        onBlur={validateImei}
        onChange={handleChangeImeiValue}
        labelLeft={t("Dialog##imei")}
        placeholder={t("Dialog##imei placeholder")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        data-testid="description-input-field"
        size="medium"
        value={descriptionValue}
        fullWidth
        errorText={descriptionIsValid ? "" : descriptionInputError}
        onBlur={validateDescriptionLength}
        onChange={handleChangeDescription}
        labelLeft={t("Dialog##description")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <div>
        <Checkbox
          id={uuidv4()}
          label={t("Dialog##deactivate vehicle")}
          onChange={handleToggleDeactivateVehicle}
          checked={isVehicleDeactivated}
        />
      </div>
    </>
  );
};

export default EditVehicleDialogContent;
