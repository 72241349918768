import { useTranslation } from "react-i18next";

import ScheduleDetailsContent from "./ScheduleDetailsContent/ScheduleDetailsContent";
import { DriverScheduleDto, ScheduleDto } from "../../../../../types";
import Dialog from "../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../Dialog/types/enums";
import { useMediaQueries } from "../../../../../../../shared";
import DialogActions from "../../../../../../../shared/components/buttons/DialogActions";

interface ScheduleDetailsDialogProps {
  schedule: ScheduleDto | DriverScheduleDto;
  isOpen: boolean;
  handleClose: () => void;
  isDriversPage?: boolean;
}

const ScheduleDetailsDialog = ({
  schedule,
  isOpen,
  handleClose,
  isDriversPage,
}: ScheduleDetailsDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="schedule-details-dialog"
      title={t(`Schedules##schedule details`, { scheduleName: schedule?.name })}
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      extendToMaxWidth={toMd}
      close={handleClose}
      actions={
        <DialogActions
          onClose={handleClose}
          isLoading={false}
          isreadyToConfirm
          hideAcceptButton
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="schedule-details-cancel-button"
        />
      }
    >
      <ScheduleDetailsContent
        schedule={schedule}
        isDriversPage={isDriversPage}
      />
    </Dialog>
  );
};

export default ScheduleDetailsDialog;
