/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import DetailsCardSingleItem from "../../DetailsCardSingleItem/DetailsCardSingleItem";

import { DriverScheduleDto, ScheduleDto } from "../../../../../types";

import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../../BannerMessage/BannerMessage";

import { TABLE_NAMES, useDateTime } from "../../../../../../../shared";
import useCustomResources from "../../../../../../../shared/hooks/useCustomResources/useCustomResources";

interface IScheduleDetailsCardContentProps {
  schedule: ScheduleDto | DriverScheduleDto;
  resourceName: TABLE_NAMES;
  lastDownloadAttempt?: boolean;
  lastSuccessfulDownload?: boolean;
  currentStatus?: boolean;
}

const ScheduleDetailsCardContent = ({
  schedule,
  resourceName,
  lastDownloadAttempt,
  lastSuccessfulDownload,
  currentStatus,
}: IScheduleDetailsCardContentProps) => {
  const { renderLastDownloadAttemptBadge } = useCustomResources();

  const { formattedDate } = useDateTime();
  const { t } = useTranslation();

  const { quick, wasLastAttemptSuccessful } = schedule;

  const bannerData = wasLastAttemptSuccessful
    ? {
        status: BANNER_MESSAGE_STATUSES.Success,
        title: t("Schedules##quick download##success banner"),
      }
    : {
        status: BANNER_MESSAGE_STATUSES.Info,
        title: t(`Schedules##quick download##info banner##${resourceName}`),
      };

  return (
    <>
      {quick && (
        <BannerMessage
          data-testid="quick-download-schedule-message"
          status={bannerData.status}
          title={bannerData.title}
          css={css({ margin: "26px 0 16px 0" })}
        />
      )}

      {currentStatus && (
        <DetailsCardSingleItem
          title={t("Details##current status")}
          textToRender={t(`Details##${schedule.currentStatus}`)}
          alignUnset
        />
      )}
      {lastDownloadAttempt && (
        <DetailsCardSingleItem
          title={
            quick ? t("Details##status") : t("Details##last download attempt")
          }
        >
          <div css={css({ alignSelf: "baseline" })}>
            {renderLastDownloadAttemptBadge(wasLastAttemptSuccessful)}
          </div>
        </DetailsCardSingleItem>
      )}
      {lastSuccessfulDownload && (
        <DetailsCardSingleItem
          title={
            quick
              ? t("Details##download time")
              : t("Details##last successful download")
          }
          textToRender={
            formattedDate(schedule.lastSuccessfulDownloadTime) ?? "-"
          }
          alignUnset
        />
      )}
    </>
  );
};

export default ScheduleDetailsCardContent;
