import { CSSObject } from "@emotion/react";

export const useStyles = (bubbleOnly?: boolean) =>
  ({
    badgeDiv: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      borderRadius: "12px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: bubbleOnly ? "3px" : "3px 7px",
      whiteSpace: "nowrap",

      span: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        svg: {
          fontSize: "16px",
        },
      },
    },
    badgeIconLeft: {
      marginRight: "4px",
    },
    badgeIconRight: {
      marginLeft: "4px",
    },
  } as CSSObject);
