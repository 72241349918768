/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useLocation, useNavigate } from "react-router-dom";

import Controls from "../Controls";
import { useStyles } from "../../styles";
import CompanySelector from "../CompanySelector/CompanySelector";
import Logo from "../../../Logo/Logo";
import MobileMenuButton from "../../../MobileMenuButton/MobileMenuButton";
import { navigateHome } from "../../../../shared/functions/functions";
import { useStyles as sharedStyles } from "../../../../shared/styles/sharedStyles";
import { useMediaQueries } from "../../../../shared";
import { FEATURE_NAMES } from "../../../../shared/featureToggles/types";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../shared/featureToggles/hooks";

const NavbarHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toMd } = useMediaQueries();

  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const { header, headerContent, navbarItemsBlock } = useStyles();
  const { logo } = sharedStyles();

  const isAllowedToViewCompanySelector = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.ShowCompanySelector,
    user?.role
  );

  return (
    <header data-testid="navbar-header" css={css(header)}>
      <div css={css(headerContent)}>
        <Logo
          css={css(logo)}
          onClick={() => navigateHome(location, navigate)}
        />
        <div css={css(navbarItemsBlock)}>
          {isAllowedToViewCompanySelector && <CompanySelector />}

          {toMd ? <MobileMenuButton /> : <Controls />}
        </div>
      </div>
    </header>
  );
};

export default NavbarHeader;
