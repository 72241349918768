import { CSSObject } from "@emotion/react";
import { useContext } from "react";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

export const useStyles = (
  isSelected?: boolean,
  isCreateNewSchedule?: boolean,
  isVehiclesSchedule?: boolean
) => {
  const {
    colors: {
      blue100,
      blue200,
      blue400,
      blue700,
      gray100,
      gray200,
      textDark,
      textSecondary,
      white,
    },
  } = useContext(ThemeContext);

  return {
    dialogContent: {
      alignItems: "center",
      display: "flex",
      columnGap: "4px",
      padding: "0 16px",
    },
    scheduleCards: {
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
    },

    singleScheduleCard: {
      display: "block",
      alignItems: "center",
      borderRadius: "8px",
      padding: "16px",
      boxSizing: "border-box",
      height: isVehiclesSchedule ? "144px" : "134px",
      border: `1px solid ${gray200}`,
    },

    globalScheduleInfo: {
      color: textDark,
      fontSize: "16px",
      display: "flex",
    },
    scheduleTitle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    scheduleCardRows: {
      "> div": {
        display: "flex",
        gridTemplateColumns: "auto auto",
        paddingBottom: "8px",
        color: textDark,
      },
    },
    scheduleCardFirstColumn: {
      color: textSecondary,
      width: "88px",
      paddingRight: "4px",
    },
    scheduleCardButtons: {
      display: "flex",
      marginLeft: "auto",
      position: "absolute",
      columnGap: "4px",
      top: "17px",
      right: "17px",
    },

    singleScheduleCardClickable: {
      backgroundColor: isSelected && !isCreateNewSchedule ? blue100 : white,
      transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: isSelected && !isCreateNewSchedule ? blue200 : gray100,
      },

      "&:active": {
        boxShadow: "none",
        backgroundColor: isSelected && !isCreateNewSchedule ? blue400 : blue100,
      },
    },

    createNewSchedule: { color: blue700 },

    scheduleActivitiesDates: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },

    scheduleIconAndTitle: {
      display: "flex",
      alignItems: "center",
      columnGap: "4px",
      margin: "2px 0 8px 0",
      color: textDark,
    },
    activitiesInfo: { color: blue700, fontSize: "16px", display: "flex" },
    activitiesTitleIconCSS: {
      display: "flex",
      alignItems: "center",
      columnGap: "4px",
      margin: "16px 0 4px 0",
    },
  } as CSSObject;
};
