/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Fragment, useContext, useEffect, useState } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import useResizeObserver from "use-resize-observer";

import { useStyles } from "./styles";
import ArrowButton from "./components/ArrowButton";
import useNews from "./hooks/useNews";
import { NewsDto } from "./types";
import CreateNewsButton from "./components/CreateNewsButton";
import SingleNew from "./components/SingleNews/SingleNews";

import CardWrapper from "../../CardWrapper/CardWrapper";

import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { useStyles as sharedStyles } from "../../../../shared";
import { useOverflow } from "../../../../shared/hooks/useOverflow";
import {
  desktopDrawerState,
  newsDataState,
  newsScrollOffset,
  tableLoading,
} from "../../../../atoms/atoms";
import { FEATURE_NAMES } from "../../../../shared/featureToggles/types";
import { useReleaseFeatureToggle } from "../../../../shared/featureToggles/hooks";

const News = () => {
  const { colors } = useContext(ThemeContext);

  const { isReleaseFeatureEnabledFromBE } = useReleaseFeatureToggle();
  const { isLoading } = useNews();
  const { t } = useTranslation();
  const { measuredRef, ref } = useOverflow();

  const isTableLoading = useRecoilValue(tableLoading);
  const newsData = useRecoilValue(newsDataState);
  const isDesktopdrawerOpen = useRecoilValue(desktopDrawerState);

  const [scrollOffsetNumber, setScrollOffsetNumber] = useRecoilState(
    newsScrollOffset
  );

  const [endOfScrollForward, setEndOfScrollForward] = useState(false);
  const [endOfScrollBackward, setEndOfScrollBackward] = useState(true);
  const [isOverflow, setIsOverflowing] = useState(false);

  const { ref: cardRef } = useResizeObserver();

  const separatorMargin = "0 16px 0 0 ";
  const isEmpty = newsData?.length === 0;

  const { verticalSeparator } = sharedStyles(colors, { separatorMargin });
  const {
    newsContentBox,
    rightArrowButton,
    leftArrowButton,
    emptyNews,
  } = useStyles({
    colors,
  });

  const loading = isTableLoading || isLoading;

  useEffect(() => {
    const isOverflowing = ref.current?.scrollWidth > ref.current?.clientWidth;

    setIsOverflowing(isOverflowing);
    // scroll to the last position after the news is loaded
    ref.current && ref.current?.scrollTo({ left: scrollOffsetNumber });

    // Reset initial scroll button state
    setTimeout(() => {
      scrollOffsetNumber === 0 && setEndOfScrollForward(false);
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    measuredRef,
    isOverflow,
    ref.current?.clientWidth,
    ref.current?.scrollWidth,
    ref,
    isLoading,
    isDesktopdrawerOpen,
    cardRef,
    newsData,
    scrollOffsetNumber,
  ]);

  const renderListOfNews = () =>
    newsData?.map((news: NewsDto, index: number) => {
      const isLast = index === newsData?.length - 1;
      const isOnlyOne = newsData?.length === 1;
      const isFirst = index === 0;
      return (
        // uuid() method impacts additional re-rendering on alert closing
        <Fragment key={`${news.title}${news.createdAt}`}>
          <SingleNew newsData={news} noMargin={isLast && !isOnlyOne} />
          {/* very first new is separated with the separator */}
          {isFirst && !isOnlyOne && <div css={css([verticalSeparator])} />}
        </Fragment>
      );
    });

  const scroll = (scrollOffset: number) => {
    const initialScroll = ref.current.scrollLeft + scrollOffset;
    setScrollOffsetNumber(initialScroll);
    ref.current.scrollLeft += scrollOffset;

    const isEndOfScrollForward =
      initialScroll + ref.current.clientWidth >= ref.current.scrollWidth;

    const isEndOfScrollBackwards = initialScroll <= 0;

    isEndOfScrollForward
      ? setEndOfScrollForward(true)
      : setEndOfScrollForward(false);

    isEndOfScrollBackwards
      ? setEndOfScrollBackward(true)
      : setEndOfScrollBackward(false);
  };

  return (
    <CardWrapper
      titleLoaderTestId="news-title-loader"
      isTopSection
      cardTitle={t("Dashboard##news")}
      isLoading={loading}
      // with this ref the width is always observed and the scroll is always updated
      reference={cardRef}
      titleInlineAction={
        isReleaseFeatureEnabledFromBE(FEATURE_NAMES.ManageNews) && (
          <CreateNewsButton />
        )
      }
    >
      {!loading && newsData && (
        <div
          css={css(
            isEmpty
              ? [emptyNews]
              : [newsContentBox, { scrollBehavior: "smooth" }]
          )}
          ref={measuredRef}
        >
          {isEmpty ? (
            <Typography variant="body1">{t("Dashboard##no news")}</Typography>
          ) : (
            <>
              {isOverflow && (
                <ArrowButton
                  scroll={scroll}
                  customStyles={leftArrowButton}
                  endOfScroll={endOfScrollBackward || scrollOffsetNumber === 0}
                  icon={<ChevronLeftOutlinedIcon />}
                  scrollOffset={-200}
                  testId="left-arrow-button"
                />
              )}

              {renderListOfNews()}

              {isOverflow && (
                <ArrowButton
                  scroll={scroll}
                  customStyles={rightArrowButton}
                  endOfScroll={endOfScrollForward}
                  icon={<ChevronRightOutlinedIcon />}
                  scrollOffset={200}
                  testId="right-arrow-button"
                />
              )}
            </>
          )}
        </div>
      )}
    </CardWrapper>
  );
};

export default News;
