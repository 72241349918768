/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeviceStatusDto } from "../../types";
import {
  centerItemsLineHeight,
  diameter,
  emptyDoughnut,
  options,
  topAlignment,
} from "../../config";
import { DeviceStatus } from "../../types/enums";
import { useStyles } from "../../styles";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { COLORS, useFormattedNumber } from "../../../../../../shared";

Chart.register(Tooltip, Legend, ArcElement);

interface DoughnutChartProps {
  deviceStatus: DeviceStatusDto | null;
}

const DoughnutChart = ({ deviceStatus }: DoughnutChartProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const [offlineStat, setOfflineStat] = useState<number>(0);
  const [inactiveStat, setInactiveStat] = useState<number>(0);
  const [onlineStat, setOnlineStat] = useState<number>(0);
  const [deactivatedStat, setDeactivatedStat] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { formatedNumber } = useFormattedNumber(totalCount);

  const { canvasHolder } = useStyles({ colors });

  useEffect(() => {
    deviceStatus && setTotalCount(deviceStatus.totalCount);
    deviceStatus &&
      deviceStatus.summary.map((i: any) => {
        switch (i.name) {
          case DeviceStatus.Online:
            return setOnlineStat(i.count);
          case DeviceStatus.Offline:
            return setOfflineStat(i.count);
          case DeviceStatus.Inactive:
            return setInactiveStat(i.count);
          case DeviceStatus.Deactivated:
            return setDeactivatedStat(i.count);
          default:
            return null;
        }
      });
  }, [
    deviceStatus,
    setOfflineStat,
    setInactiveStat,
    setOnlineStat,
    setDeactivatedStat,
    setTotalCount,
  ]);

  const oneDevice = deviceStatus?.totalCount === 1;

  // Data array for the chart
  const statusArray = [onlineStat, offlineStat, inactiveStat, deactivatedStat];

  const maxNumberInStatusArray = statusArray
    .filter((i) => i !== 0)
    .reduce((a, b) => Math.max(a, b), 0);

  // empty space width is 1% of the max number in the status array
  const emptySpace = maxNumberInStatusArray * 0.01;

  // check if data array has more then two elements to be able to manipulate spacing between elements
  const hasMoreThenTwoElements = statusArray.filter((i) => i !== 0).length > 2;

  //  check if data array element value is not 0
  const hasValueByStatusArrayIndex = (index: number) =>
    statusArray[index] !== 0;

  const checkIfSingleStatusHasValue = () => {
    const statusArrayWithoutZero = statusArray.filter((i) => i !== 0);
    return statusArrayWithoutZero.length === 1;
  };

  const insertEmptySpaceValue = (
    index: number,
    value: number | COLORS | string
  ) => hasMoreThenTwoElements && hasValueByStatusArrayIndex(index) && value;

  const insertEmptySpace = (index: number) =>
    insertEmptySpaceValue(index, emptySpace);

  const insertEmptySpaceColor = (index: number) => {
    const emptySpaceColor = colors.white;
    return insertEmptySpaceValue(index, emptySpaceColor);
  };

  const insertEmptySpaceBorder = (index: number) => {
    const emptySpaceBorder = "transparent";
    return insertEmptySpaceValue(index, emptySpaceBorder);
  };

  const data: any = {
    datasets: [
      {
        data: [
          statusArray[0],
          // Since spacing between data elements can be manipulated only with white border color,
          // we need to add empty space with white color to separate chart elements.
          insertEmptySpace(0),
          statusArray[1],
          insertEmptySpace(1),
          statusArray[2],
          insertEmptySpace(2),
          statusArray[3],
          insertEmptySpace(3),
        ],
        labels: [
          DeviceStatus.Online,
          undefined,
          DeviceStatus.Offline,
          undefined,
          DeviceStatus.Inactive,
          undefined,
          DeviceStatus.Deactivated,
        ],
        backgroundColor: [
          colors.green600,
          insertEmptySpaceColor(0),
          colors.gray200,
          insertEmptySpaceColor(1),
          colors.white,
          insertEmptySpaceColor(2),
          colors.red700,
          insertEmptySpaceColor(3),
        ],
        borderRadius: 2,
        borderWidth: 1,
        borderColor: [
          colors.green600,
          insertEmptySpaceBorder(0),
          deviceStatus?.totalCount !== 0 ? colors.gray400 : colors.gray200,
          insertEmptySpaceBorder(1),
          colors.gray400,
          insertEmptySpaceBorder(2),
          colors.red700,
          insertEmptySpaceBorder(3),
        ],

        // This property works only with two or less elements in the chart.
        spacing: checkIfSingleStatusHasValue()
          ? 0
          : !hasMoreThenTwoElements && !oneDevice && 2,
      },
    ],
  };

  return (
    <div
      id="canvas-holder"
      style={canvasHolder as {}}
      data-testid="canvas-holder"
    >
      <Doughnut
        id="doughnut-chart"
        data={data}
        options={options(colors, data)}
        width={+diameter}
        height={+diameter}
        plugins={[emptyDoughnut]}
        style={{ cursor: "pointer" }}
      />

      {/* centered total count of devices */}
      <div
        css={css({
          position: "absolute",
          width: "100%",
          top: `${topAlignment}px`,
          left: 0,
          textAlign: "center",
          lineHeight: `${centerItemsLineHeight}px`,
        })}
      >
        {deviceStatus && (
          <>
            <Typography variant="body2">{formatedNumber}</Typography>
            <Typography variant="subtitle1semiBold">
              {t("Dashboard##devices")}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default DoughnutChart;
