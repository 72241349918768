/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Tooltip from "./Tooltip";

interface LongTextTooltipProps {
  children: any;
  title: any;
  open: boolean;
}

export const LongTextTooltip = ({
  children,
  title,
  open,
}: LongTextTooltipProps) => {
  return (
    <Tooltip
      size="medium"
      placement="top"
      title={title}
      disableHoverListener={!open}
      css={css({
        "& .MuiTooltip-tooltip": {
          maxWidth: "unset",
        },
      })}
    >
      {children}
    </Tooltip>
  );
};
