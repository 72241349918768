/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, SetStateAction } from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { useTranslation } from "react-i18next";

import { MenuAnchorEl } from "../../types";

import Tooltip from "../../../components/Tooltip/Tooltip";
import Button from "../../../components/Button/Button";
import Menu from "../../../components/Menu/Menu";

interface MoreActionsButtonProps {
  onMenuOpen: (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
    stopPropagation: () => void;
  }) => void;
  anchorEl: MenuAnchorEl;
  onCloseMenu: () => void;
  children: ReactNode;
  menuWidth?: string;
  customStyle?: any;
}

const MoreActionsButton = ({
  onMenuOpen,
  anchorEl,
  onCloseMenu,
  children,
  menuWidth,
  customStyle,
}: MoreActionsButtonProps) => {
  const { t } = useTranslation();

  return (
    <div css={css(customStyle)}>
      <Tooltip title={t("Actions##actions")} placement="top" size="medium">
        <Button
          data-testid="more-actions-menu-button"
          size="tiny"
          variant="iconOnly"
          color="secondary"
          icon={<MoreVertRoundedIcon />}
          onClick={onMenuOpen}
          css={css({ backgroundColor: "transparent" })}
        />
      </Tooltip>

      <Menu
        data-testid="more-actions-menu"
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        MenuListProps={{ disableListWrap: true }}
        css={css({
          "& .MuiMenu-paper": {
            width: menuWidth || "211px",
          },
        })}
      >
        <div>{children}</div>
      </Menu>
    </div>
  );
};

export default MoreActionsButton;
