/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import ResourceScheduleCards from "./components/ResourceScheduleCards/ResourceScheduleCards";
import DetailsTitleArea from "./components/DetailsTitleArea";
import VehicleGeneralDetails from "./components/GeneralDetails/VehicleGeneralDetails";
import DriverGeneralDetails from "./components/GeneralDetails/DriverGeneralDetails";
import CompaniesGeneralDetails from "./components/GeneralDetails/CompaniesGeneralDetails";
import CompanyCardsDetails from "./components/CompanyCardsDetails/CompanyCardsDetails";
import {
  DESKTOP_DETAILS_MARGIN_BOTTOM,
  DESKTOP_DETAILS_MARGIN_TOP,
  MAX_DESCRIPTION_LENGTH,
} from "../../constants";
import Separator from "../../../Separator/Separator";
import { pageGlobalStateObjectResult } from "../../../Table/functions";
import {
  tableDetails,
  tableDetailsLoading,
  tableLoading,
} from "../../../../atoms/atoms";
import {
  DETAILS_TITLE_AREA_FIXED_HEIGHT,
  INITIAL_DETAILS_TITLE_AREA_HEIGHT_WITH_SHOW_MORE,
  NAVBAR_HEIGHT,
  ROUTES,
  useMediaQueries,
} from "../../../../shared";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

interface IDetailsContentProps {
  onClose?: () => void;
}

const DetailsContent = ({ onClose }: IDetailsContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd, toMd } = useMediaQueries();
  const location = useLocation();

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);
  const detailsSectionData = useRecoilValue(tableDetails);

  const [titleAreaHeight, setTitleAreaHeight] = useState<number | null>(0);

  const detailsData = pageGlobalStateObjectResult(
    location.pathname,
    detailsSectionData
  );

  // the number below defines the min height not to brake the UI
  const finalDetailsTitleAreaHeightOnFirstRender = 147;
  // the number below defines the ref.current.clientHeight value in DetailsTitleArea component on first render
  const titleAreaRefHeightOnFirstRender =
    detailsData?.comment?.length > MAX_DESCRIPTION_LENGTH
      ? INITIAL_DETAILS_TITLE_AREA_HEIGHT_WITH_SHOW_MORE
      : DETAILS_TITLE_AREA_FIXED_HEIGHT;

  // Since titleAreaHeight on first render calculates less height then it should be (by using DetailsTitleArea ref variable),
  // it is overritten in the variable below.
  // After the 'show more' button click, dynamically rendered number returns correct value.
  const renderedTitleAreHeight =
    titleAreaHeight && titleAreaHeight === titleAreaRefHeightOnFirstRender
      ? finalDetailsTitleAreaHeightOnFirstRender
      : titleAreaHeight;
  const desktopDetailsTitleAreHeight = `calc(${renderedTitleAreHeight}px - ${DESKTOP_DETAILS_MARGIN_TOP})`;

  const displayLoader = isDetailsLoading || isTableLoading;
  const detailsContentHeightToMd = `calc(100vh - ${desktopDetailsTitleAreHeight} - ${NAVBAR_HEIGHT})`;
  const detailsContentHeightFromMd = `calc(100vh - ${desktopDetailsTitleAreHeight}  - ${NAVBAR_HEIGHT} - ${DESKTOP_DETAILS_MARGIN_TOP} - ${DESKTOP_DETAILS_MARGIN_BOTTOM} - 34px)`;

  // company details content does not have margin bottom
  const companyDetailsocntentHeight = `calc(100vh - ${desktopDetailsTitleAreHeight}  - ${NAVBAR_HEIGHT} - ${DESKTOP_DETAILS_MARGIN_TOP})`;

  const renderGeneralDetails = () => {
    switch (location.pathname) {
      case ROUTES.Vehicles:
        return <VehicleGeneralDetails detailsData={detailsData} />;
      case ROUTES.Drivers:
        return <DriverGeneralDetails detailsData={detailsData} />;
      case ROUTES.Companies:
        return <CompaniesGeneralDetails detailsData={detailsData} />;

      default:
        return <></>;
    }
  };

  const renderSchedules = () => {
    const validRoutes = [ROUTES.Vehicles, ROUTES.Drivers];

    if (!validRoutes.includes(location.pathname as ROUTES)) {
      return <></>;
    }

    return (
      <ResourceScheduleCards
        detailsData={detailsData}
        isLoading={displayLoader}
        pageLocation={location.pathname as ROUTES}
      />
    );
  };

  const renderCompanyCards = () =>
    location.pathname === ROUTES.Companies ? (
      <CompanyCardsDetails
        detailsData={detailsData}
        isLoading={displayLoader}
      />
    ) : (
      <></>
    );

  const calculateDetailsContentHeight = () => {
    if (fromMd) {
      return location.pathname !== ROUTES.Companies
        ? detailsContentHeightFromMd
        : companyDetailsocntentHeight;
    } else {
      return detailsContentHeightToMd;
    }
  };

  return (
    <div
      css={css({
        width: `100%`,
        height: "100%",
        marginBottom:
          fromMd && location.pathname !== ROUTES.Companies
            ? DESKTOP_DETAILS_MARGIN_BOTTOM
            : "unset",
      })}
    >
      <DetailsTitleArea
        displayLoader={displayLoader}
        detailsData={detailsData}
        onClose={onClose}
        location={location}
        setTitleAreaHeight={setTitleAreaHeight}
      />
      <Separator color={colors.gray200} />
      <div
        css={css({
          height: calculateDetailsContentHeight(),
          overflowY: "scroll",
          padding: toMd ? "0 16px 0 0" : "unset",
        })}
      >
        {renderGeneralDetails()}
        {renderSchedules()}
        {renderCompanyCards()}
      </div>
    </div>
  );
};

export default DetailsContent;
