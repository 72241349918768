import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SCREEN_NAME } from "./types";
import ApiManagementContent from "./ApiManagementContent/ApiManagementContent";
import DeleteApiCredentialsDialog from "./DeleteApiCredentialsDialog";

import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { useMediaQueries } from "../../../../../../shared";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";

interface ApiManagementProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const ApiManagement = ({ isOpen, closeDialog }: ApiManagementProps) => {
  const { getData, postData, deleteData } = useAxiosPrivate();
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();
  const { handleCommonError, handleResponse } = useResponse();

  const [renderName, setRenderName] = useState<SCREEN_NAME>(SCREEN_NAME.Main);
  const [apiCredentialData, setApiCredentialData] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [showCreateButton, setShowCreateButton] = useState(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchApiCredentials();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const fetchApiCredentials = async () => {
    try {
      setIsFetchingData(true);
      const { data } = await getData(ApiResources.UsersApiCredentials);
      setApiCredentialData(data);

      const apiCredentialsExist = data.createdAt !== null;

      !apiCredentialsExist && setRenderName(SCREEN_NAME.Empty);

      setCreateDisabled(apiCredentialsExist);
    } catch (error) {
      setRenderName(SCREEN_NAME.Empty);
      handleCommonError(error, t);
    }
    setIsFetchingData(false);
  };

  const handleCreateCredentials = async () => {
    try {
      setIsSubmitLoading(true);

      const { data } = await postData(ApiResources.UsersApiCredentials);

      setApiCredentialData(data);
      setRenderName(SCREEN_NAME.CredentialsCreated);

      handleResponse(
        ALERT_STATUS.Success,
        t("Alert##api credentials create success")
      );

      setShowCreateButton(false);
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsSubmitLoading(false);
  };

  const handleDeleteApiCredentials = async () => {
    try {
      setIsDeleteLoading(true);
      await deleteData(ApiResources.UsersApiCredentials);

      setIsDeleteOpen(false);
      handleResponse(
        ALERT_STATUS.Success,
        t("Alert##api credentials delete success")
      );

      await fetchApiCredentials();
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <Dialog
        data-testid="api-management-dialog"
        title={t("Dialog##api management##title")}
        description={t("Dialog##api management##description")}
        submit={handleCreateCredentials}
        isTitleSeparator
        isActionsSeparator
        extendToMaxWidth={toMd}
        width={!toMd ? DialogWidth.MinWidth : undefined}
        actions={
          <DialogActions
            onClose={closeDialog}
            isLoading={isSubmitLoading}
            isreadyToConfirm={!createDisabled}
            hideAcceptButton={!showCreateButton}
            confirmationButtonText={t("Button##create")}
            confirmationButtonTestId="api-management-create-button"
            cancelButtonText={t("Button##close")}
            cancelButtonTestId="api-management-close-button"
          />
        }
        TransitionProps={{
          onExited: () => {
            setCreateDisabled(true);
            setRenderName(SCREEN_NAME.Main);
            setShowCreateButton(true);
            setApiCredentialData(null);
          },
        }}
        close={closeDialog}
        open={isOpen}
      >
        <ApiManagementContent
          apiCredentialData={apiCredentialData}
          renderName={renderName}
          isLoading={isFetchingData}
          handleOpenDelete={() => setIsDeleteOpen(true)}
        />
      </Dialog>

      <DeleteApiCredentialsDialog
        isOpen={isDeleteOpen}
        isLoading={isDeleteLoading}
        closeDialog={() => setIsDeleteOpen(false)}
        handleDelete={handleDeleteApiCredentials}
      />
    </>
  );
};

export default ApiManagement;
