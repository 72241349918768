/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DeviceStatusSummaryType } from "../../types";
import { useStyles } from "../../styles";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { useFormattedNumber } from "../../../../../../shared";

interface SingleDeviceStatItemProps {
  item: DeviceStatusSummaryType;
}

const SingleDeviceStatItem = ({ item }: SingleDeviceStatItemProps) => {
  const { colors } = useContext(ThemeContext);
  const { formatedNumber } = useFormattedNumber(item.count);

  const { t } = useTranslation();

  const {
    singleStatItemBox,
    singleStatItemMarkStick,
    singleItemTextBox,
  } = useStyles({ colors, item });

  return (
    <div css={css(singleStatItemBox)}>
      <div css={css(singleStatItemMarkStick)}></div>
      <div css={css(singleItemTextBox)}>
        <Typography variant="body2" color={colors.textDark}>
          {formatedNumber}
        </Typography>
        <Typography variant="body2" color={colors.textPlaceholder}>
          {t(`Dashboard##${item.name}`)}
        </Typography>
      </div>
    </div>
  );
};

export default SingleDeviceStatItem;
