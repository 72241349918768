import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
// this package is used to be able to make ErrorBoundary as functional HOC
// it could be changed into Sentry error handling when Sentry is integrated
import { useErrorBoundary, withErrorBoundary } from "react-use-error-boundary";

import { TITLES } from "./types";
import ErrorComponent from "./components/ErrorComponent";
import { ROUTES } from "../..";
import errorAnimation from "../../../assets/animations/error_animation.json";
import { initialLoading, serverError, tableData } from "../../../atoms/atoms";

interface IErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary = withErrorBoundary(({ children }: IErrorBoundaryProps) => {
  // if there is a need to pass fallback, then works in this way:
  // ({ ...props }: any) => {

  const [error, resetError] = useErrorBoundary();
  const location = useLocation();

  const [isServerError, setIsServerError] = useRecoilState(serverError);

  const originalPathname = useRef(location.pathname);

  const [, setTableRowData] = useRecoilState(tableData);
  const [, setIsInitialLoading] = useRecoilState(initialLoading);

  useEffect(() => {
    if (location.pathname !== originalPathname.current) {
      resetError();
    }
    setIsServerError(undefined);
  }, [location.pathname, resetError, setIsServerError]);

  const handleButtonClick = () => {
    const newState = { page: "redirect" };
    location.pathname === ROUTES.Vehicles
      ? window.location.reload()
      : // redirect to home page without rendering before redirect is called
        window.history.replaceState(newState, "Redirect", ROUTES.Vehicles);
    window.location.replace(ROUTES.Vehicles);

    // reset table view to avoid undefined values in UI:
    setIsInitialLoading(true);
    setTableRowData([]);
  };

  if (error || Boolean(isServerError)) {
    return (
      <ErrorComponent
        onButtonClick={handleButtonClick}
        title={TITLES.Error}
        animation={errorAnimation}
        message={"Error##message wait"}
        pathname={location.pathname}
      />
    );
  }

  return <div>{children}</div>;
});

export default ErrorBoundary;
