import { useTranslation } from "react-i18next";
import CardWrapper from "../../CardWrapper/CardWrapper";

const Credits = () => {
  const { t } = useTranslation();

  return (
    <CardWrapper isTopSection cardTitle={t("Dashboard##credits")} isCreditsCard>
      <></>
    </CardWrapper>
  );
};

export default Credits;
