import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import AddCompanyCardDialogContent from "./components/AddCompanyCardDialogContent";
import { DetailsDataType } from "../../../../types";
import useTableItemDetails from "../../../../hooks/useTableItemdetails";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import { useMediaQueries } from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";

interface AddCompanyCardDialogProps {
  open: boolean;
  onClose: () => void;
  detailsData: DetailsDataType;
}

const AddCompanyCardDialog = ({
  open,
  onClose,
  detailsData,
}: AddCompanyCardDialogProps) => {
  const { postData } = useAxiosPrivate();
  const { toMd } = useMediaQueries();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>(null);

  const resetAllValues = () => {
    setIsReady(false);
    setPayload(null);
  };

  const handleSubmit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);
        await postData(ApiResources.CompanyCards, payload);

        // refresh table details
        getTableItemDetails({
          itemId: payload.companyId,
          successMessage: t("Alert##add company card success"),
          leaveTableDetailsNotUpdated: false,
          valueInSearchField: undefined, // companies page does not have search field,
        });

        onClose();
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          const responseData: string = err.response?.data
            ? t(`Alert##${err.response?.data.message}`)
            : t("Alert##action failed");
          handleResponse(ALERT_STATUS.Critical, responseData);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      title={t("Dialog##add company card")}
      open={open}
      close={onClose}
      narrowPadding
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      TransitionProps={{
        onExited: () => resetAllValues(),
      }}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="add-new-card-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="add-new-card-cancel-button"
        />
      }
    >
      <AddCompanyCardDialogContent
        setPayload={setPayload}
        setIsReadyToConfirm={setIsReady}
        detailsData={detailsData}
      />
    </Dialog>
  );
};

export default AddCompanyCardDialog;
