/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Location } from "react-router";

import {
  MARGIN_LEFT_AND_RIGHT,
  MAX_DESCRIPTION_LENGTH,
} from "../../../constants";
import { renderTitle } from "../../../functions";
import { DetailsDto } from "../../../types";
import { useStyles } from "../../../styles";

import Button from "../../../../Button/Button";
import Tooltip from "../../../../Tooltip/Tooltip";
import TextLink from "../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../TextLink/types";

import { useMediaQueries } from "../../../../../shared";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";
import { LongTextTooltip } from "../../../../Tooltip/LongTextTooltip";
import { useOverflow } from "../../../../../shared/hooks/useOverflow";

interface IDetailsTitleAreaProps {
  displayLoader: boolean;
  detailsData: DetailsDto;
  onClose: (() => void) | undefined;
  location: Location;
  setTitleAreaHeight: Dispatch<SetStateAction<number | null>>;
}

const DetailsTitleArea = ({
  displayLoader,
  detailsData,
  onClose,
  location,
  setTitleAreaHeight,
}: IDetailsTitleAreaProps) => {
  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();
  const { measuredRef, isOverflowing } = useOverflow();

  const [showMoreText, setShowMoreText] = useState<boolean>(false);

  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  const { detailsTitle } = useStyles();

  useLayoutEffect(() => {
    setTitleAreaHeight(height);
    setHeight(ref.current.clientHeight);
  }, [showMoreText, height, setTitleAreaHeight, isOverflowing]);

  const showCloseButton = (fromMd && detailsData) || (fromMd && displayLoader);

  const handleToggleShowMore = () => {
    setShowMoreText(!showMoreText);
  };

  const showMore = (textToShow: string) => {
    if (textToShow?.length > MAX_DESCRIPTION_LENGTH) {
      return (
        <>
          <Typography
            variant="body1Regular"
            sx={{ overflowWrap: "break-word" }}
          >
            {!showMoreText
              ? `${textToShow.slice(0, MAX_DESCRIPTION_LENGTH)}...`
              : textToShow}{" "}
          </Typography>
          <TextLink
            size={TEXT_LINK_SIZES.Large}
            onClick={handleToggleShowMore}
            sx={{ overflowWrap: "break-word" }}
          >
            {!showMoreText ? t("Details##show more") : t("Details##show less")}
          </TextLink>
        </>
      );
    }
    return <Typography variant="body1Regular">{textToShow} </Typography>;
  };

  return (
    <div
      ref={ref}
      data-testid="details-title-area"
      css={css({
        width: `calc(100% - ${MARGIN_LEFT_AND_RIGHT})`,
        margin: "0 auto",
        padding: "22px 24px 24px 24px",
      })}
    >
      <div
        css={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        {displayLoader ? (
          <RectangleLoader
            height="24"
            width="216px"
            testId="details-data-number-loader"
          />
        ) : (
          detailsData && (
            <LongTextTooltip
              title={renderTitle(detailsData, location)}
              open={isOverflowing}
            >
              <Typography
                ref={measuredRef}
                variant="h6"
                css={css(detailsTitle)}
              >
                {renderTitle(detailsData, location)}
              </Typography>
            </LongTextTooltip>
          )
        )}
        {showCloseButton && (
          <Tooltip
            data-testid="close-details-tooltip"
            title={t("Details##close details")}
            placement="top"
            size="medium"
            hasArrow
          >
            <Button
              variant="iconOnly"
              size="small"
              color="white"
              icon={<CloseIcon />}
              onClick={onClose}
            />
          </Tooltip>
        )}
      </div>
      <div>
        {displayLoader ? (
          <RectangleLoader height="20" width={fromMd ? "100%" : "380px"} />
        ) : (
          detailsData && showMore(detailsData.comment)
        )}
      </div>
    </div>
  );
};

export default DetailsTitleArea;
