import { NavigateFunction, Location } from "react-router-dom";

import {
  INPUT_LENGTH,
  LANGUAGES,
  LOCAL_STORAGE_KEYS,
  ROUTES,
  RefreshTableAndUpdateDetailsProps,
} from "..";

import { ALERT_STATUS } from "../../context/alert/types";
import { ENVIRONMENTS } from "../../routes/types";

export const getValidLanguage = (lang: any, defaultLang: LANGUAGES) => {
  const isValid = Object.values(LANGUAGES).includes(lang);

  return isValid ? (lang as LANGUAGES) : defaultLang;
};

export const setStoredLanguage = (language: string) =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.lng, language);

export const getStoredLanguage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.lng);

export const deleteStoredLanguage = () =>
  localStorage.removeItem(LOCAL_STORAGE_KEYS.lng);

export const navigateHome = (
  location: Location,
  navigate: NavigateFunction
) => {
  navigate(ROUTES.Vehicles, { state: { from: location }, replace: true });
};

export const getItemFromLocalStorage = (itemKey: string) =>
  localStorage.getItem(itemKey);

export const setItemToLocalStorage = (itemKey: string, itemValue: string) =>
  localStorage.setItem(itemKey, itemValue);

export const parseResource = (name: string, data: any): string | null | any => {
  let result = data[name];

  return result;
};

export const generateColor = (
  inverted: boolean | undefined,
  firstcolor: string,
  secondColor: string
) => (inverted ? firstcolor : secondColor);

export const capitalizeResourceFirstLetter = (string: string) => {
  return string.charAt(1).toUpperCase() + string.slice(2);
};

export const removeSlashFromPath = (path: string) => path.replace("/", "");

export const capitalizeStringFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeStringOnlyFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export const capitalizeLocationFirstLetter = (string: string) => {
  return string.charAt(1).toUpperCase() + string.slice(2);
};

export const isDriverPayloadReadyToConfirm = (
  cardHolderName: string | null,
  cardHolderNameIsValid: boolean | string | null,
  driverId: string | null,
  inputIsValid: () => boolean,
  descriptionIsValid: boolean
) =>
  cardHolderName &&
  cardHolderNameIsValid &&
  driverId &&
  inputIsValid() &&
  descriptionIsValid;

export const descriptionIsValid = (descriptionValue: string | null) =>
  (descriptionValue && descriptionValue.length <= INPUT_LENGTH.Description) ||
  !descriptionValue;

export const cardHolderNameIsValid = (cardHolderName: string | null) =>
  cardHolderName && cardHolderName.length <= INPUT_LENGTH.Name;

export const checkIfDataTypeIsDisabled = (
  givenDataType: string,
  schedule: any
) => {
  let result;
  const givenFileTypes = schedule?.fileTypes || schedule;

  if (givenFileTypes?.includes(givenDataType)) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

export const numberRegex = /\d+/;
export const letterRegex = /\D/g;

export const getNumberFromString = (givenString: string) =>
  givenString?.replace(letterRegex, "");

export const getStringWithoutNumber = (givenString: string) =>
  givenString?.replace(numberRegex, "");

export const passwordLengthIsValid = (password: string | null) =>
  (password && password.length <= INPUT_LENGTH.MaxPassword) || !password;

export const routesWithoutDetails = (pathname: string) => {
  if (pathname === ROUTES.VehicleFiles || pathname === ROUTES.DriverFiles) {
    return true;
  } else {
    return false;
  }
};

export const replaceStringWithSpacesToCamelCase = (string: string) => {
  let result: string = "";
  string.split(" ").forEach((str: string) => {
    result = result + str[0].toUpperCase() + str.slice(1);
  });
  return result;
};

export const replaceCamelCaseStringToSeparateWords = (camelcase: string) => {
  return camelcase.replace(/([a-z](?=[A-Z]))/g, "$1 ");
};

export const refreshTableAndUpdateDetails = async (
  parameters: RefreshTableAndUpdateDetailsProps
): Promise<void> => {
  const {
    valueInSearchField,
    resourceId,
    successMessage,
    itemHasOpenedDetails,
    getTableItemDetails,
    handleResponse,
  } = parameters;

  getTableItemDetails({
    itemId: resourceId,
    successMessage: successMessage,
    leaveTableDetailsNotUpdated: !itemHasOpenedDetails,
    fromTableRowClick: false,
    fromEditItem: true,
    valueInSearchField: valueInSearchField ?? undefined,
  });

  if (valueInSearchField && successMessage) {
    handleResponse(ALERT_STATUS.Success, successMessage);
  }
};

export const getEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  return {
    env: env,
    isProductionEnv: env === ENVIRONMENTS.Production,
    isDevelopmentEnv: env === ENVIRONMENTS.Development,
    isStagingEnv: env === ENVIRONMENTS.Staging,
  };
};
