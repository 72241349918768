/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { MutableRefObject, useRef } from "react";
import { useStyles } from "../../styles";
import { useIsXOverflow } from "../../../../shared/hooks/useIsXOverflow";
import { LongTextTooltip } from "../../../Tooltip/LongTextTooltip";

interface IDialogTitleWithTooltipProps {
  title: string;
  tooltipText: string;
}

const DialogTitleWithTooltip = ({
  title,
  tooltipText,
}: IDialogTitleWithTooltipProps) => {
  const ref: MutableRefObject<null> | null = useRef(null);
  const isOverflowing = useIsXOverflow(ref);

  const { dialogTitleWithTooltip } = useStyles();

  return (
    <LongTextTooltip title={tooltipText} open={isOverflowing}>
      <div css={css(dialogTitleWithTooltip)} ref={ref}>
        {title}
      </div>
    </LongTextTooltip>
  );
};

export default DialogTitleWithTooltip;
