/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "../styles";
import Tooltip from "../../Tooltip/Tooltip";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface InfoIconWithTooltipProps {
  title: string;
  customIconStyle?: {};
  iconTestId?: string;
}

const InfoIconWithTooltip = ({
  title,
  customIconStyle,
  iconTestId,
}: InfoIconWithTooltipProps) => {
  const { colors } = useContext(ThemeContext);

  const { infoIcon } = useStyles({ colors });

  return (
    <Tooltip title={title} size="medium" placement="top" hasArrow>
      <span css={css(customIconStyle)}>
        <InfoIcon
          data-testid={iconTestId || "info-icon-with-tooltip"}
          css={css(infoIcon)}
        />
      </span>
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
