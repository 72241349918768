import { CSSObject } from "@emotion/react";

import { diameter } from "./config";
import { DeviceStatusSummaryType } from "./types";
import { statusBorderColor, statusColor } from "./functions";

import { ThemeColors } from "../../../../shared";

interface UseStylesProps {
  colors?: ThemeColors;
  item?: DeviceStatusSummaryType;
  toSm?: boolean;
}

export const useStyles = ({ colors, item, toSm }: UseStylesProps) =>
  ({
    canvasHolder: {
      width: `${diameter}px`,
      height: `${diameter}px`,
      position: "relative",
    },
    singleStatItemBox: {
      display: "flex",
      marginBottom: "12px",
      marginRight: "16px",
    },
    singleStatItemMarkStick: {
      width: "8px",
      height: "32px",
      marginRight: "8px",
      backgroundColor: item && colors && statusColor(item.name, colors),
      border:
        item && colors && `1px solid ${statusBorderColor(item.name, colors)}`,
      borderRadius: "2px",
      alignSelf: "center",
    },
    singleItemTextBox: { display: "flex", flexDirection: "column" },

    deviceStatusContentContainer: { display: "flex" },

    statusListBox: {
      width: toSm ? "100%" : "184px",
      height: toSm ? "unset" : "166px",
      display: "flex",
      flexDirection: "column",
      flexWrap: toSm ? "unset" : "wrap",
      marginLeft: "24px",
      justifyContent: "flex-start",
    },
  } as CSSObject);
