import { Paper, Typography } from "@mui/material";
import { ReactNode, useContext } from "react";

import { useStyles } from "./styles";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface PaperBoxProps {
  children: ReactNode;
  customStyling?: any;
  boxTitle?: string;
}

const PaperBox = ({ children, customStyling, boxTitle }: PaperBoxProps) => {
  const { colors } = useContext(ThemeContext);

  const { paperBox, boxTitleArea } = useStyles({ colors });

  return (
    <div data-testid="paper-box">
      {boxTitle && (
        <Typography variant="body2" sx={boxTitleArea as {}}>
          {boxTitle}
        </Typography>
      )}
      <Paper
        elevation={0}
        variant="outlined"
        sx={[paperBox as {}, customStyling]}
      >
        {children}
      </Paper>
    </div>
  );
};

export default PaperBox;
