import { useContext } from "react";
import { useTranslation } from "react-i18next";

import AddCompanyCardButton from "../AddCompanyCardButton/AddCompanyCardButton";
import AccordionDetailsCard from "../../../AccordionDetailsCard/AccordionDetailsCard";
import { DetailsDataType, DETAILS_TITLES } from "../../../../../../types";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../../../BannerMessage/BannerMessage";
import Separator from "../../../../../../../Separator/Separator";
import { ThemeContext } from "../../../../../../../../context/theme/ThemeContextProvider";
import { COMPANY_CARDS_LIMITS } from "../../../../../../../../Pages/Companies/types";

interface NoCompanyCardsProps {
  currentCount: number;
  detailsData: DetailsDataType;
}

const NoCompanyCards = ({ currentCount, detailsData }: NoCompanyCardsProps) => {
  const { t } = useTranslation();
  const { colors } = useContext(ThemeContext);

  return (
    <AccordionDetailsCard
      cardTitle={t(`Details##${DETAILS_TITLES.CompanyCards}`)}
      maxCount={COMPANY_CARDS_LIMITS.MaxCompanyCardsCount}
      currentCount={currentCount}
      accordionItems={[]}
      quickTitleAction={
        <AddCompanyCardButton
          detailsData={detailsData}
          cardsCount={currentCount}
        />
      }
    >
      <Separator color={colors.gray200} />
      <BannerMessage
        data-testid="no-company-cards-banner"
        status={BANNER_MESSAGE_STATUSES.Warning}
        title={t("Details##no company cards")}
        externalCustomStyle={{ margin: "16px" }}
      />
    </AccordionDetailsCard>
  );
};

export default NoCompanyCards;
