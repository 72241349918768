import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { USERS_TABLE_COLUMNS } from "./config";
import { USERS_PAGE_TABS } from "./UsersInvitations/config";
import PageWrapperNoDetails from "../wrappers/PageWrapperNoDetails";
import LayoutWrapper from "../../components/Layout/LayoutWrapper";
import UsersHeaderActions from "../../components/PageHeader/actions/pageHeaderActions/UsersHeaderActions/UsersHeaderActions";
import useUsersInlineActions from "../../components/Table/actions/inlineActions/hooks/useUsersInlineActions";
import useTableData from "../../components/Table/hooks/useTableData";
import Table from "../../components/Table/Table";
import { ROUTES, TABLE_NAMES } from "../../shared";
import EditUserDialog from "../../components/Table/actions/dialogs/Users/EditUserDialog/EditUserDialog";
import TabFilters from "../../components/TabFilters/TabFilters";
import { isTableCheckable } from "../../atoms/atoms";

export const mapUserTableRowData = (data: any) =>
  data.map((dataItem: any) => {
    return {
      id: dataItem.id,
      email: dataItem.email,
      // name the property with the original name from response at the mentioned level
      // in this case - "name"
      name: dataItem.company.name,
      role: dataItem.role,
      invitedBy: dataItem.invitedBy,
      invitedAt: dataItem.invitedAt,
      updatedAt: dataItem.updatedAt,
      company: dataItem.company,
    };
  });

const Users = () => {
  useTableData(mapUserTableRowData);
  const {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  } = useUsersInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  useEffect(() => {
    setIsCheckable(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWrapperNoDetails
        currTableName={TABLE_NAMES.Users}
        currTableColumns={USERS_TABLE_COLUMNS}
        inlineActions={inlineActions}
      >
        <LayoutWrapper showDetailsSection={false}>
          <UsersHeaderActions currentRoute={ROUTES.Users} />
        </LayoutWrapper>

        <Table
          hasDetails={false}
          hideFilters
          hasTabFilters
          tabFilters={<TabFilters tabs={USERS_PAGE_TABS} />}
        />
      </PageWrapperNoDetails>

      {/* inline action dialog */}
      {rowData && (
        <EditUserDialog
          isOpen={isOpen}
          onClose={closeDialog}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default Users;
