/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../../context/Auth/AuthProvider";
import TextLink from "../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../components/TextLink/types";

const BackToSignIn = () => {
  const { toggle2FaVerification } = useAuth();

  const { t } = useTranslation();

  const handleBacktoSignIn = () => {
    toggle2FaVerification(false);
  };

  return (
    <Fragment>
      <span
        css={css({
          marginBottom: "8px",
        })}
      >
        {t("Auth##account verification description")}
      </span>

      <TextLink size={TEXT_LINK_SIZES.Large} onClick={handleBacktoSignIn}>
        {t("Auth##back to sign in screen")}
      </TextLink>
    </Fragment>
  );
};

export default BackToSignIn;
