/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useStyles } from "./styles";
import ContentContainer from "./ContentContainer/ContentContainer";

import DashboardWrapper from "../wrappers/DashboardWrapper";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import LayoutNoDetails from "../../components/Layout/LayoutNoDetails";
import { tableName } from "../../atoms/atoms";

const Dashboard = () => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const currentTableName = useRecoilValue(tableName);

  const { dashboardContainer } = useStyles({ colors });

  return (
    <DashboardWrapper>
      <div css={css(dashboardContainer)}>
        <LayoutNoDetails headerTitle={t(`General##${currentTableName}`)}>
          <></>
        </LayoutNoDetails>
        <ContentContainer />
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
