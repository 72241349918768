import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../types";

export const useStyles = (colors?: ThemeColors, ...props: any) =>
  ({
    body3regular: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",

      "& .MuiTypography-root": {
        letterSpacing: "0.4px",
      },
    },
    body3semiBold: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "& .MuiTypography-root": {
        letterSpacing: "0.4px",
      },
    },
    body4semibold: {
      fontSize: "10px",
      fontWeight: 600,
      lineHeight: "12px",
      letterSpacing: "1px",
    },
    logo: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    pageLayout: {
      display: "flex",
      width: `100%`,
    },
    flexbox: {
      display: "flex",
    },
    verticalSeparator: {
      alignSelf: "stretch",
      flexShrink: "0",
      width: "1px",
      background: colors?.gray100,
      margin: props[0]?.separatorMargin || "0 16px",
    },
  } as CSSObject);
