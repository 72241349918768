/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import AuthContainer from "./components/AuthContainer";
import ContactUs from "./components/ContactUs";
import useTogglePswVisibility from "./hooks/useTogglePswVisibility";
import usePasswordValidation from "./hooks/usePasswordValidation";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import { loginFailedMessage } from "./constants";
import { INPUT_FIELD, PASSWORD_TYPE } from "./types";
import BackToSignIn from "./components/BackToSignIn";
import useLogin from "./hooks/useLogin";
import { useTwoFactorVerification } from "./hooks";

import InputField from "../../components/InputField/InputField";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../components/Button/Button";
import { useEmailValidation } from "../../shared/hooks/useEmailValidation";
import { useAuth } from "../../context/Auth/AuthProvider";
import { ALERT_STATUS } from "../../context/alert/types";
import axios from "../../api/axios";
import useResponse from "../../shared/hooks/useResponse";
import { loginState, twoFactorVerificationState } from "../../atoms/atoms";
import BannerMessage from "../../components/BannerMessage/BannerMessage";
import { BANNER_MESSAGE_STATUSES } from "../../components/BannerMessage/types/enums";
import { inputFieldMarginBottom } from "../../shared";
import { OtpInputField } from "../../shared/auth/OtpInputField";
import { ApiResources } from "../../api/resources";

const Login = () => {
  const {
    setIsLoading,
    errorMessageFromServer,
    setErrorMessageFromServer,
  } = useAuth();

  const { handleResponse } = useResponse();
  const { t } = useTranslation();

  const is2FaVerification = useRecoilValue(twoFactorVerificationState);
  const [, setIsLoginState] = useRecoilState(loginState);

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [token, setToken] = useState<string>("");

  const {
    emailIsValid,
    emailError,
    setEmailError,
    clearEmailError,
    validateEmail,
  } = useEmailValidation(userName);

  const {
    passwordError,
    setPasswordError,
    clearPasswordError,
  } = usePasswordValidation();

  const { PasswordVisibility, showPassword } = useTogglePswVisibility(
    PASSWORD_TYPE.Current
  );

  const {
    handleEmailInputChange,
    handlePswInputChange,
    display2FaVerificationScreen,
    storeTokensAndNavigateToHomepage,
  } = useLogin({
    setUserName,
    validateEmail,
    clearEmailError,
    setPassword,
    clearPasswordError,
  });

  const {
    handle2FaSubmit,
    twoFaError,
    handleOtpChange,
    otpCode,
  } = useTwoFactorVerification({
    setIsLoading,
    setIsButtonLoading,
    token,
  });

  useEffect(() => {
    setIsBannerOpen(errorMessageFromServer);
  }, [errorMessageFromServer, is2FaVerification, isBannerOpen]);

  // Typescript has no way to verify which types of values might be thrown by arbitrary code. So up until recently, the error had to be of type any.
  const handleError = (error: any) => {
    // Check if failed login related to incorrect email and/or password
    const incorrectLoginData =
      error.response?.data?.message === loginFailedMessage;

    if (incorrectLoginData) {
      setErrorMessageFromServer(true);
    } else {
      handleResponse(
        ALERT_STATUS.Critical,
        error?.response?.data?.message || error?.message
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (emailIsValid && password.length > 0) {
      const payload = {
        userName: userName,
        password: password,
      };

      try {
        // set login state to display auth loader on Users/Me request
        setIsLoginState(true);
        setIsLoading(true);
        setIsButtonLoading(true);
        const response = await axios.post(ApiResources.CreateToken, payload);

        const { twoFactorEnabled } = response.data;

        twoFactorEnabled && display2FaVerificationScreen(response, setToken);

        // set tokens only if twoFA is not enabled
        if (!twoFactorEnabled) {
          storeTokensAndNavigateToHomepage(response);
        }
      } catch (error) {
        if (error) {
          handleError(error);
        }
      } finally {
        setIsLoading(false);
        setIsButtonLoading(false);
      }
    } else {
      if (!emailIsValid) {
        setEmailError(t("Auth##invalid email format"));
      }

      if (password.length < 1) {
        setPasswordError({
          ...passwordError,
          password1: t("Auth##required"),
        });
      }
    }
  };

  return (
    <AuthContainer
      formName={
        is2FaVerification
          ? t("Auth##account verification")
          : t("Auth##sign in to tacho")
      }
      secondaryText={is2FaVerification ? <BackToSignIn /> : <ContactUs />}
    >
      <form
        noValidate
        onSubmit={is2FaVerification ? handle2FaSubmit : handleSubmit}
        data-testid={is2FaVerification ? "two-fa-verification" : "login-form"}
      >
        {isBannerOpen && (
          <BannerMessage
            status={BANNER_MESSAGE_STATUSES.Critical}
            title={t("Auth##failed to login")}
            externalCustomStyle={inputFieldMarginBottom}
          />
        )}
        {is2FaVerification ? (
          <>
            <OtpInputField
              isLoading={isButtonLoading}
              error={twoFaError}
              onChange={handleOtpChange}
            />

            <Button
              data-testid="verify-button"
              fullWidth
              color={BUTTON_COLORS.Primary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              type="submit"
              disabled={otpCode.length < 6}
              isLoading={isButtonLoading}
              css={css({
                marginTop: "16px",
              })}
            >
              {t("Button##verify")}
            </Button>
          </>
        ) : (
          <>
            <InputField
              data-testid="email-field"
              size="medium"
              id="standard-error-helper-text"
              fullWidth
              name={INPUT_FIELD.Email}
              onBlur={validateEmail}
              errorText={emailIsValid ? "" : emailError}
              onChange={handleEmailInputChange}
              labelLeft={t("Auth##email")}
              placeholder={`${t("Auth##example")} email@tacho.com`}
              customStyle={{ marginBottom: "16px" }}
              value={userName}
            />
            <InputField
              data-testid="password-field"
              fullWidth
              value={password}
              name={INPUT_FIELD.Password}
              labelLeft={t("Auth##password")}
              placeholder={t("Auth##enter here")}
              size="medium"
              type={showPassword.current ? "text" : "password"}
              onChange={handlePswInputChange}
              errorText={passwordError.password1}
              iconRight={<PasswordVisibility />}
            />
            <ForgotPassword />
            <Button
              data-testid="sign-in-button"
              fullWidth
              variant="textOnly"
              size="normal"
              color="primary"
              type="submit"
              isLoading={isButtonLoading}
            >
              {t("Auth##sign in")}
            </Button>
          </>
        )}
      </form>
    </AuthContainer>
  );
};

export default Login;
