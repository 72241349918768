/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import { useStyles } from "../styles";
import { useStyles as useReusableDetailsStyles } from "../../../../../styles";
import useDetailsFunctions from "../../../../../hooks/useDetailsFunctions/useDetailsFunctions";
import { SCHEDULES_NAMES } from "../../../../../../Schedules/types";
import Separator from "../../../../../../Separator/Separator";
import GlobalScheduleIcon from "../../../../../../Schedules/components/GlobalScheduleIcon/GlobalScheduleIcon";
import { specificGlobalSchedule } from "../../../../../../Schedules/functions";
import SharedStatusBadge from "../../../../../../SharedStatusBadge/SharedStatusBadge";
import {
  tableDetailsLoading,
  tableLoading,
} from "../../../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import { COMPANY_CARD_STATUS, MenuAnchorEl } from "../../../../../../../shared";
import MoreActionsButton from "../../../../../../../shared/components/buttons/MoreActionsButton";

interface SingleDetailsAcordionItemprops {
  title: string;
  totalCount: number;
  index: number;
  collapsable?: boolean;
  children: ReactNode;
  titleInlineActions?: ReactNode;
  anchorEl?: MenuAnchorEl;
  menuWidth?: string;
  companyCardStatus?: COMPANY_CARD_STATUS;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setIsActionsOpen?: Dispatch<SetStateAction<MenuAnchorEl>>;
}

const SingleDetailsAcordionItem = ({
  title,
  totalCount,
  index,
  collapsable,
  children,
  titleInlineActions,
  anchorEl,
  menuWidth,
  companyCardStatus,
  setIsLoading,
  setIsActionsOpen,
}: SingleDetailsAcordionItemprops) => {
  const { colors } = useContext(ThemeContext);
  const { renderExpandIcon } = useDetailsFunctions();
  const { collapseArrow } = useReusableDetailsStyles();
  const location = useLocation();

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    accordionItemTitleArea,
    collapsableItemContentDiv,
    accordionItemTitleContainer,
    notCollapsableItemContentDiv,
    accordionItemIconMargin,
  } = useStyles({
    isOpen,
  });

  const isFirstItemAllowedToCollapse = index === 0;
  const displayLoader = isDetailsLoading || isTableLoading;
  const collapsableCardContent = isOpen ? children : <></>;

  useEffect(() => {
    // reset isOpen state on loader to avoid state cache
    displayLoader && setIsOpen(false);
    isFirstItemAllowedToCollapse && setIsOpen(true);
    setIsLoading && setIsLoading(displayLoader);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, setIsLoading, displayLoader]);

  const handleToggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenActionsMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setIsActionsOpen && setIsActionsOpen(e.currentTarget);
  };

  const handleCloseActionsMenu = () => {
    setIsActionsOpen && setIsActionsOpen(null);
  };

  return (
    <div data-testid="single-details-accordion-item">
      {index < totalCount && <Separator color={colors.gray200} />}

      <div css={css(accordionItemTitleContainer)}>
        {/* title */}
        <div
          css={css([
            accordionItemTitleArea,
            companyCardStatus && accordionItemIconMargin,
          ])}
        >
          {title === SCHEDULES_NAMES.GlobalSchedule && (
            <GlobalScheduleIcon
              specificGlobalSchedule={specificGlobalSchedule(location.pathname)}
              colors={colors}
            />
          )}

          {companyCardStatus && (
            <SharedStatusBadge status={companyCardStatus} bubbleOnly />
          )}

          <Typography variant="body2Bold">{title}</Typography>
        </div>

        {/* actions menu */}
        <div css={css(accordionItemTitleArea)}>
          {titleInlineActions && (
            <MoreActionsButton
              onMenuOpen={handleOpenActionsMenu}
              onCloseMenu={handleCloseActionsMenu}
              anchorEl={anchorEl || null}
              menuWidth={menuWidth}
            >
              {titleInlineActions}
            </MoreActionsButton>
          )}

          {/* arrow icon */}
          <div css={css(collapseArrow)}>
            {collapsable &&
              renderExpandIcon({
                handleToggleCollapse,
                isOpen,
                customStyle: {},
                hasTooltip: true,
              })}
          </div>
        </div>
      </div>

      {/* Card content */}
      {collapsable && (
        <div css={css(collapsableItemContentDiv)}>{collapsableCardContent}</div>
      )}

      {!collapsable && (
        <div css={css(notCollapsableItemContentDiv)}>{children}</div>
      )}
    </div>
  );
};

export default SingleDetailsAcordionItem;
