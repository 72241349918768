/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import useDeviceStatus from "./hooks/useDeviceStatus";
import DoughnutChart from "./components/DoughnutChart/DoughnutChart";
import SingleDeviceStatItem from "./components/SingleDeviceStatItem/SingleDeviceStatItem";
import { useStyles } from "./styles";

import CardWrapper from "../../CardWrapper/CardWrapper";

import { deviceStatusState, tableLoading } from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../shared";

const DeviceStatus = () => {
  const { isLoading } = useDeviceStatus();
  const { toSm } = useMediaQueries();

  const { t } = useTranslation();
  const isTableLoading = useRecoilValue(tableLoading);
  const deviceStatus = useRecoilValue(deviceStatusState);

  const { deviceStatusContentContainer, statusListBox } = useStyles({ toSm });

  const loading = isTableLoading || isLoading;

  const generateStatusList = (deviceStatus: any) =>
    deviceStatus &&
    deviceStatus.summary.map((i: any) => (
      <SingleDeviceStatItem key={i.name} item={i} />
    ));

  return (
    <CardWrapper
      // height setting on toSm screen is not needed here
      isTopSection={!toSm}
      cardTitle={t("Dashboard##device status")}
      isLoading={loading}
    >
      {loading ? (
        <RectangleLoader
          width={"100%"}
          height={"100%"}
          testId="device-status-loader"
        />
      ) : (
        deviceStatus && (
          <div css={css(deviceStatusContentContainer)}>
            <DoughnutChart deviceStatus={deviceStatus} />
            <div css={css(statusListBox)}>
              {generateStatusList(deviceStatus)}
            </div>
          </div>
        )
      )}
    </CardWrapper>
  );
};

export default DeviceStatus;
